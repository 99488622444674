import useTranslate from "../_common/_Hooks/useTranslate"
import Button, { Buttons } from "../_common/_components/Button/Button"
import Checkbox from "../_common/_components/Checkbox/Checkbox"
import Input from "../_common/_components/Input/Input"
import ModalContent from "../_common/_components/ModalContent/ModalContent"
import Select from "../_common/_components/Select/Select"
import TextArea from "../_common/_components/TextArea/TextArea"
import Slider from '../_common/_components/Slider/Slider'
import { Modals } from "../_common/_components/ModalsContainer/ModalsContainer";
import { IMG_BASE_URL } from '../config'
import './Modals.scss'
import RadioButtons from "../_common/_components/RadioButtons/RadioButtons"
import { useRef, useState } from "react"
import Grid from "../_common/_components/Grid/Grid"
import FileUpload from "../_common/_components/FileUpload/FileUpload"
import useForm from "../_common/_Hooks/useForm"
import Flex from "../_common/_components/Flex/Flex"
import Files from "../_common/_components/Files/Files"
import usePasswordProtect from "../_common/_Hooks/usePasswordProtect"
import Phone from "../_common/_components/Phone/Phone"
import { classNameMaker } from "../helpers"
import AudioPlayer from 'react-h5-audio-player';
import Hint from "../_common/_components/Hint/Hint"
import Table from "../_common/_components/Table/Table"
import ArrayCollector from "../_common/_components/ArrayCollector/ArrayCollector"

export default ({ setShow }) => {

    const { t, _lang, lang } = useTranslate()

    return {
        showCloseButton: 'left 15',
        // **************************************************************************************************************************************
        Success({ msg }) {
            return (
                <ModalContent autoHide hideCloseButton>
                    <h3>{t('Success Message')}</h3>
                    <p>{msg}</p>
                </ModalContent>
            )
        },
        // **************************************************************************************************************************************
        Error({ msg }) {
            return (
                <ModalContent css={1} autoHide hideCloseButton>
                    <h3>Error Message</h3>
                    <p>{msg}</p>
                </ModalContent>
            )
        },
        // **************************************************************************************************************************************
        Confirm({ action }) {
            const { t } = useTranslate()
            return (
                <ModalContent>
                    <h3 lang={lang}>{`${t("Are you sure to")} ${modalQuestionCorrector(action, t)} ${t("?")}`}</h3>
                    <Button content='Cancel' css={1} field='cancel' />
                    <Button content='Confirm' css={1.1} field='confirm' />
                </ModalContent>
            )
        },
        // **************************************************************************************************************************************
        Confirm_with_comment({ action, user }) {
            const { t } = useTranslate()
            const checkbox_condition = (["closed", 'need_info', 'escalated', 'resolved'].includes(action?.handle_key) &&
                !(user.is_manager || user.is_sub_admin || user.is_admin))
            return (
                <ModalContent>
                    <TextArea css={2} placeholder='Comment Here' field='comment' label={`${t("Are you sure to")} ${modalQuestionCorrector(action.action_status, t)} ${t("?")}`} required />
                    {/* <Checkbox css={2} reverse toggleSwitch field='approvalRequired' label="Require Manager Approval" condition={checkbox_condition} /> */}
                    <Button content='Cancel' css={1} field='cancel' />
                    <Button content='Submit' css={1.1} field='submit' validator />
                </ModalContent>
            )
        },
        // **************************************************************************************************************************************
        Confirm_with_dropDown({ content, action, user, details }) {
            const { t } = useTranslate()
            const isAlreadyInPool = details?.assigned_to?.length === 0
            const isAssign_or_Share = (action.handle_key === 'assigned' || action.handle_key === 'share') && !isAlreadyInPool
            const isAssign = action.handle_key === 'assigned'
            return (
                <ModalContent pages={isAssign_or_Share}>
                    {({ inputs }) => {
                        const isPool = inputs.assing_to_type === "pool"
                        return (
                            <>
                                {isAssign_or_Share && <>
                                    <RadioButtons css={2} label={isAssign ? "Assign to" : "Share to"} field='assing_to_type' required
                                        buttons={
                                            [
                                                { label: "Pool", value: "pool" },
                                                { label: "User", value: "user" },
                                            ]}
                                    />
                                    <Button content='Cancel' css={1} field='cancel' />
                                    <Button content={isPool ? 'Submit' : 'Next'} css={1.1} field='submit' validator next={!isPool} />
                                </>}
                                <>
                                    <h3>{t(content.heading)}</h3>
                                    <Select left css={1} content={{ op: content.selectOptions, vl: '_id', lb: content.selectLabel }}
                                        className='mb-1' field={content.field} required />
                                    <Button content={isAssign_or_Share ? 'Back' : 'Cancel'} field='cancel' css={1} back={isAssign_or_Share} />
                                    <Button content='Submit' css={1.1} field='submit' validator />
                                </>
                            </>
                        )
                    }}
                </ModalContent>
            )
        },
        // **************************************************************************************************************************************
        Add_Edit_department({ isEdit }) {
            return (
                <ModalContent>
                    <h3>{`${isEdit ? 'Edit' : 'Add'} Department`}</h3>
                    <Input css={1} placeholder='Enter Department Name' label='Department [En]' field='department_name_en' required />
                    <Input css={1} placeholder='Enter Department Name' label='Department [Ar]' field='department_name_ar' required />
                    <Button content='Cancel' css={1} field='cancel' />
                    <Button content='Submit' css={1.1} field='submit' validator />
                </ModalContent>
            )
        },
        // **************************************************************************************************************************************
        Add_Edit_IncidentType({ isEdit }) {
            return (
                <ModalContent>
                    <h3>{t(`${isEdit ? 'Edit' : 'Add'} Incident Type`)}</h3>
                    <Input css={1} placeholder='Enter Incident Type' label='Incident Type [En]' field='incident_type_en' required />
                    <Input css={1} placeholder='Enter Incident Type' label='Incident Type [Ar]' field='incident_type_ar' required />
                    <hr />
                    <h6>Sub Types</h6>
                    <Grid columns={2}>
                        <Input css={1} placeholder='Enter Incident Type' label='Incident Type [En]' field='incident_type_en' required />
                        <Input css={1} placeholder='Enter Incident Type' label='Incident Type [Ar]' field='incident_type_ar' required />
                    </Grid>
                    <Button content='Cancel' css={1} field='cancel' />
                    <Button content='Submit' css={1.1} field='submit' validator />
                </ModalContent>
            )
        },
        // **************************************************************************************************************************************
        Edit_Translation({ data }) {
            const { t } = useTranslate()
            return (
                <ModalContent>
                    <h3>{t('Edit Translation')}</h3>
                    <h6>{t('Title')} : {data.title} </h6>
                    <Input css={1} placeholder='Enter English Text' label='English' field='english_text' required mb={15} />
                    <Input css={1} placeholder='Enter Arabic Text' label='Arabic' field='arabic_text' required />
                    <Button content='Cancel' css={1} field='cancel' />
                    <Button content='Submit' css={1.1} field='submit' validator />
                </ModalContent>
            )
        },
        // **************************************************************************************************************************************
        InstagramPosts({ data, index }) {
            return (
                <Slider css={1} type={2} initial={index} >
                    {
                        data?.map((item, i) => {
                            return (
                                <div className="insta_post" key={i}>
                                    <img className='close_icon' src="/assets/icons/close.svg" alt="" onClick={() => setShow(false)} />
                                    <Slider css={2} type={2}>
                                        {item.instagram_post.map((s_item, k) => {
                                            let type = s_item.split('.').pop()
                                            return (
                                                type === 'mp4'
                                                    ? <video key={k} className='insta_post_file' controls src={`${IMG_BASE_URL}/profile/${s_item}`} />
                                                    : <img key={k} className='insta_post_file' src={`${IMG_BASE_URL}/profile/${s_item}`} alt='' />
                                            )
                                        })}
                                    </Slider>
                                    <div className="slider-caption">
                                        <div className='profile'>
                                            <img className='profile_pic' src="/assets/img/instagram-profile-pic.jpg" alt="" />
                                            <p className='profile_name'>
                                                alameenservice
                                                <img className='verified' src="/assets/icons/insta_verified.svg" alt="" />
                                            </p>
                                        </div>
                                        <p className="post-content">
                                            {item?.descripition}
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
            )
        },
        // **************************************************************************************************************************************
        ForgotPassword() {
            return (
                <ModalContent css={1} width={'auto'}>
                    <h3>{t('Please contact Admin to reset your password')}</h3>
                </ModalContent>
            )
        },
        // **************************************************************************************************************************************
        instaFeedRequest({ action }) {
            return (
                <ModalContent>
                    <TextArea css={2} placeholder='Comment Here' field='comment' label={`${t("Are you sure to")} ${t(action.action_status)} ${t("?")}`} required />
                    <Button content='Cancel' css={1} field='cancel' />
                    <Button content='Submit' css={1.1} field='submit' validator />
                </ModalContent>
            )
        },
        // **************************************************************************************************************************************
        AddVolunteer({ isEdit, fileUploader }) {
            return (
                <ModalContent width={800} fileUploader={fileUploader}>
                    <h3>{isEdit ? 'Edit Volunteer' : 'Add Volunteer'}</h3>
                    <div>
                        <Grid columns={2}>
                            <Input css={1} label='First Name' field={'first_name'} required />
                            <Input css={1} label='Last Name' field={'last_name'} required />
                            <Input css={1} label='Phone' field={'phone_number'} required type='number' validation={{ phone: { digits: "6-13" }, maxNumber: 13 }} />
                            <Input css={1} label='Email' field={'email'} required validation={{ email: true }} />
                        </Grid>
                    </div>
                    <FileUpload
                        css={2}
                        mt={20}
                        field='eid'
                        label="Emirates ID"
                        dragNdrop
                        // moreText='Upload Emirates ID'
                        buttonLabel="Drag and Drop 'Emirates ID' or click here"
                        maxFileSizeMB={50}
                        accept={{ items: ["pdf"], message: 'Only pdf format accepted' }}
                        showPreview
                        icon={'/assets/icons/upload.svg'}
                        multiple
                    />
                    <FileUpload
                        css={3}
                        mt={20}
                        field='attachments'
                        label="Supporting Documents"
                        limited={3}
                        maxFileSizeMB={50}
                        accept={{ items: ["jpg", "jpeg", "png", "pdf", "doc", "docx"], message: 'Only jpg, jpeg, png, pdf, doc and docx formats accepted' }}
                        showPreview
                        icon={'/assets/icons/plus_black.svg'}
                        multiple
                    />
                    <Button content='Cancel' css={1} field='cancel' onClick={() => { }} />
                    <Button content='Submit' css={1.1} field='submit' validator />
                </ModalContent>
            )
        },
        // **************************************************************************************************************************************
    }

}






export const ActionsModalHandler = async (inputs, action, _lang, user, data) => {

    let content = {
        forward: {
            heading: 'Forward to Department',
            selectLabel: 'department_name' + _lang,
            selectOptions: data.forwardDepartments,
            field: 'frwrd_to_department'
        },
        assigned: {
            heading: 'Assign User',
            selectLabel: (item) => `(${item.assigned_to_count}) ${item['first_name' + _lang]}`,
            selectOptions: data.deptUsers,
            field: 'assigned_to'
        },
        share: {
            heading: 'Share to User',
            selectLabel: 'first_name' + _lang,
            selectOptions: data.users_to_share,
            field: 'shared_assignee'
        }
    }

    return ['forward', 'assigned', 'share'].includes(action?.handle_key)
        ? await Modals.Confirm_with_dropDown({
            m_inputs: inputs,
            content: content[action?.handle_key],
            action: action,
            user: user,
            details: data.details,
        })
        : await Modals.Confirm_with_comment({
            m_condition: data.page === 'addRequest' ? !['draft', 'processing'].includes(action?.handle_key) : true,
            m_inputs: inputs,
            action: action,
            user: user,
            m_condition: data.condition
        })
}








// **************************************************************************************************************************************
export const EditAlternatives = ({ M, type, data, inputs: inp, customerFindHandler }) => {
    const { t } = useTranslate()
    const isPhone = type === 'phone'
    const isEmail = type === 'email'
    const adding = useForm(
        isEmail ? {} : { add: { code: "+971" } }
    )
    const { formPlug, errors, setInputs, inputs, validate, setErrors, removeInputs } = useForm(
        data.length > 0
            ? isEmail
                ? { ...Object.fromEntries(data.map((d, i) => [i, d])) }
                : { ...Object.fromEntries(data.map((d, i) => [i, { number: d.number, code: d.code }])) }
            : isPhone
                ? { 0: { code: '+971' } }
                : { 0: '' }
    )
    async function addHandler() {
        setInputs(s => ({ ...s, [Object.keys(s).length]: isPhone ? { code: '+971' } : '' }))
    }
    async function submitHandler() {
        if (!await validate()) return;
        let ers = { ...errors }
        isPhone && Object.entries(inputs).map(e => {
            if (
                (e[1].number === inp.customer_detail_customer_contact) &&
                ((inp.customer_detail_customer_contact_code.code || inp.customer_detail_customer_contact_code) === (e[1]?.code?.code || e[1]?.code))
            ) {
                ers = { ...ers, [e[0]]: 'The number is already added as primary' }
            }
            if (!(e[1].number?.length > 6) && isPhone) {
                ers = { ...ers, [e[0]]: 'Invaild number' }
            }
            if (!(e[1].number?.length > 0) && isPhone) {
                ers = { ...ers, [e[0]]: 'Required field' }
            }
        })
        isEmail && Object.entries(inputs).map(e => {
            if (inp?.customer_detail_customer_email === e[1]) {
                ers = { ...ers, [e[0]]: 'The email is already added as primary' }
            }
        })
        setErrors((ers))
        if (Object.values(ers).filter(i => i).length > 0) return
        M.Handler('submit', { m_inputs: inputs })
    }
    function existingCheckHandler(e, type, key, cb) {
        customerFindHandler(e, type, (res) => {
            if (res.errorCode === 1001) {
                setErrors(s => ({ ...s, [key]: 'Number already exist' }))
                cb && cb(false)
            } else {
                cb && cb(true)
                setErrors(({ [key]: del, ...s }) => s)
            }
        })
    }
    function deleteHandler(datom) {
        let key = datom[0]
        setInputs(s => {
            let itemsCount = Object.keys(s).length
            let obj = { 0: isPhone ? { code: '+971' } : '' }
            for (let i = 0; i < (itemsCount); i++) {
                if (i < key) obj[i] = s[i]
                else if (i === (itemsCount - 1)) { }
                else obj[i] = s[i + 1]
            }
            return obj
        })
        setErrors({})
    }
    const items = Object.entries(inputs)
    const errs = [...Object.values(errors), ...Object.values(adding.errors)]
    return (
        <div id="EditAlternatives">
            <h3>{`${t(`${data.length > 0 ? 'Edit' : 'Add'} Alternative`)} ${t(type === 'phone' ? 'Contacts' : 'Emails')}`}</h3>
            {
                items?.map((datom, i) => {
                    return (
                        <div className="altr_item" key={i}>
                            <p className="sl_no">{i + 1}</p>
                            {isPhone
                                ? <Phone
                                    css={1}
                                    noField
                                    noForm
                                    type='number'
                                    field={datom[0]}
                                    validation={{ phone: { digits: "6-13" }, maxNumber: 13 }}
                                    onChange={(e, k) => {
                                        setInputs(s => ({ ...s, [datom[0]]: { ...s[datom[0]], number: e } }))
                                        setErrors(({ [datom[0]]: del, ...s }) => s)
                                    }}
                                    value={inputs[datom[0]].number}
                                    countryCode_onChange={(e, k) => setInputs(s => ({ ...s, [datom[0]]: { ...s[datom[0]], code: e.code } }))}
                                    countryCode_value={inputs[datom[0]].code}
                                    error={errors[datom[0]]}
                                    onBlur={(e) => existingCheckHandler({ number: e, code: inputs?.[datom[0]].code }, type, datom[0])}
                                />
                                : <Input css={1} field={datom[0]} required inputSocket={formPlug} validation={{ email: true }} />
                            }
                            <Hint hint={'Delete'} >
                                <img className="altr_delete" src="/assets/icons/delete.svg" alt="" onClick={() => deleteHandler(datom)} />
                            </Hint>
                        </div>
                    )
                })
            }
            {Object.keys(inputs).length < 5 && <div className="altr_item">
                <Hint hint={'Add item'} >
                    <img className="add_icon" src="/assets/icons/plus_black.svg" alt="" onClick={addHandler} />
                </Hint>
            </div>}
            <div className="errors">
                {[...new Set(errs)].map(i => <p>{i}</p>)}
            </div>
            <Buttons end mt={10}>
                <Button css={1} content="Cancel" onClick={() => M.Handler('cancel')} />
                <Button css={1.1} content="Submit" onClick={submitHandler} />
            </Buttons>
        </div>
    )
}
// **************************************************************************************************************************************
export const FindCustomer = ({ M, details, type, inputs }) => {
    const { t } = useTranslate()
    let needAlternateEmail = details?.alternate_email_added && type === 'phone'
    let needAlternatePhone = details?.alternate_phone_added && type === 'email'
    let isPhone = type === 'phone'
    let isEmail = type === 'email'
    let data = [
        // { title: 'Customer Name ar', old: inputs.customer_detail_customer_name_ar, new: details.data.customer_name_ar },
        { title: 'Customer Name', old: inputs.customer_detail_customer_name_en, new: details.data.customer_name_en },
        { title: 'Customer Contact', old: inputs.customer_detail_customer_contact, new: details.data.customer_contact },
        { title: 'Customer Country Code', old: inputs.customer_detail_customer_contact_code?.code || inputs.customer_detail_customer_contact_code, new: details.data.customer_contact_code },
        { title: 'Customer Email', old: inputs.customer_detail_customer_email, new: details.data.customer_email },
        { title: 'Customer Gender', old: t(inputs.customer_detail_customer_gender), new: t(details.data.customer_gender) },
        { title: 'Customer Nationality', old: inputs.customer_detail_customer_nationality?.name, new: details.data.customer_nationality?.name },
        { title: 'Customer Alternate Email', old: inputs.customer_alternate_email.map(i => <p>{i}</p>), new: details.data.customer_alternate_email.map(i => <p>{i}</p>) },
        { title: 'Customer Alternate Contact', old: inputs.customer_alternate_contact.map(i => <p>{i.code + " " + i.number}</p>), new: details.data.customer_alternate_contact.map(i => <p>{i.code + " " + i.number}</p>) },

        // { title: 'Email Verification', old: inputs.is_verified_email, new: details.data.is_verified_email },
        // { title: 'Contact Verification', old: inputs.is_verified_phone_number, new: details.data.is_verified_phone_number },
    ]
    return (
        <div id="FindCustomer">
            <p>
                <p className="head_title">
                    {
                        needAlternateEmail
                            ? t('Another email exist with this contact number')
                            : needAlternatePhone
                                ? t('Another contact number exist with this email')
                                : isPhone
                                    ? t('A customer already exist with this contact number')
                                    : isEmail
                                        ? t('A customer already exist with this email')
                                        : ''
                    }
                </p>
                <br />
                <table>
                    <thead>
                        <tr>
                            <th>{t('Field')}</th>
                            <th>|</th>
                            <th>{t('Entered')}</th>
                            <th>|</th>
                            <th>{t('Existing')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data?.map(datom => {
                                return (
                                    <tr className={classNameMaker(datom.title)}>
                                        <td>{t(datom.title)}</td>
                                        <td>:</td>
                                        <td className="entered">{datom.old}</td>
                                        <td>:</td>
                                        <td className="existing">{datom.new}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <br />
                <p className="qs">
                    {
                        isPhone || isEmail
                            ? t('Do you want to use it ?')
                            : t('What do you wish to do ?')
                    }
                </p>
            </p>
            <Buttons end>
                {
                    needAlternateEmail
                        ? <>
                            <Button css={1} content="Use Existing" onClick={() => M.Handler('use_existing')} />
                            <Button css={1} content="Change Number" onClick={() => M.Handler('change_number')} />
                            <Button css={1} content="Add Alternative" onClick={() => M.Handler('alternative_email')} />
                        </>
                        : needAlternatePhone
                            ? <>
                                <Button css={1} content="Use Existing" onClick={() => M.Handler('use_existing')} />
                                <Button css={1} content="Change Email" onClick={() => M.Handler('change_email')} />
                                <Button css={1} content="Add Alternative" onClick={() => M.Handler('alternative_number')} />
                            </>
                            : <>
                                <Button css={1} content="No" onClick={() => M.Handler('no')} />
                                <Button css={1.1} content="Yes" onClick={() => M.Handler('yes')} />
                            </>
                }
            </Buttons>

            <ul className="action_description">
                {
                    needAlternateEmail
                        ? <>
                            <li>{`${t('On Use Existing')} : ${t('above displayed customer data will be filled in the fields')}`}</li>
                            <li>{`${t('On Add Alternative')} : ${inputs?.customer_detail_customer_email} ${t('will be added as alternative email of the customer')}`}</li>
                        </>
                        : needAlternatePhone
                            ? <>
                                <li>{`${t('On Use Existing')} : ${t('above displayed customer data will be filled in the fields')}`}</li>
                                <li>{`${t('On Add Alternative')} : ${inputs?.customer_detail_customer_contact} ${t('will be added as alternative contact of the customer')}`}</li>
                            </>
                            : null
                }
            </ul>
        </div>
    )
}
FindCustomer.width = 800
// **************************************************************************************************************************************
export const Confirm_with_comment = ({ M, action, m_inputs }) => {
    const { t } = useTranslate()
    const { inputs, formPlug, validate } = useForm(m_inputs)
    async function submitHandler() {
        if (!await validate()) return
        M.Handler('submit', { m_inputs: inputs })
    }
    return (
        <div>
            <TextArea
                css={2}
                placeholder='Comment Here'
                field='comment'
                label={`${t("Are you sure to")} ${modalQuestionCorrector(action.action_status || action, t)} ${t("?")}`}
                inputSocket={formPlug}
                required
            />
            <Buttons end mt={20}>
                <Button content='Cancel' css={1} onClick={() => M.Handler('cancel')} />
                <Button content='Submit' css={1.1} onClick={submitHandler} />
            </Buttons>
        </div>
    )
}
// **************************************************************************************************************************************
export const Success = ({ M, msg }) => {
    const { t } = useTranslate()
    return (
        <div>
            <h3>{t('Success Message')}</h3>
            <p>{msg}</p>
        </div>
    )
}
Success.autoHide = true
Success.hideCloseBtn = true
Success.css = 1
// **************************************************************************************************************************************
export const Confirm = ({ M, action }) => {
    const { t } = useTranslate()
    return (
        <div id="Confirm">
            <h3>{`${t("Are you sure to")} ${modalQuestionCorrector(action, t)} ${t("?")}`}</h3>
            <Buttons end mt={20}>
                <Button content='Cancel' css={1} onClick={() => M.Handler('cancel')} />
                <Button content='Confirm' css={1.1} onClick={() => M.Handler('confirm')} />
            </Buttons>
        </div>
    )
}
// **************************************************************************************************************************************
export const FileViewer = ({ value, canDownload, downloader }) => {
    return (
        <div id="FileViewer">
            <Files.Preview file={value} custom={({ isAudio, file }) => {
                if (isAudio) return (
                    <AudioPlayer
                        className={'download'}
                        style={{ width: 500, marginTop: 60 }}
                        showJumpControls={false}
                        showFilledProgress={false}
                        customVolumeControls={[]}
                        customAdditionalControls={[]}
                        layout={'horizontal'}
                        src={file.url}
                    />
                )
            }} />
            {canDownload && <Button css={4} content="Download" icon='/assets/icons/dowld.svg' iconWidth={15} onClick={() => downloader()} />}
            <p className="name">{value.name}</p>
        </div>
    )
}
FileViewer.width = 700
FileViewer.className = 'fileViewer'
// **************************************************************************************************************************************
export const AddEditVolunteer = ({ M, isEdit, m_inputs, fileUploader }) => {
    const { t } = useTranslate()
    const { formPlug, inputs, validate } = useForm(m_inputs, { fileUploader })
    return (
        <div id="Volunteer">
            <h3>{t(isEdit ? 'Edit Volunteer' : 'Add Volunteer')}</h3>
            <Grid columns={2}>
                <Input css={1} inputSocket={formPlug} label='First Name' field={'first_name'} required />
                <Input css={1} inputSocket={formPlug} label='Last Name' field={'last_name'} required />
                {/* <Input css={1} inputSocket={formPlug} label='Phone' field={'phone_number'} required type='number' validation={{ phone: { digits: "6-13" }, maxNumber: 13 }} /> */}
                <Phone
                    css={1}
                    className='cll_ico'
                    label='Phone Number'
                    field={'phone_number'}
                    countryCode_field='customer_contact_code'
                    type='number'
                    validation={{ phone: { digits: "6-13" }, maxNumber: 13 }}
                    inputSocket={formPlug}
                    countryCode_inputSocket={formPlug}
                    required
                />
                <Input css={1} inputSocket={formPlug} label='Email' field={'email'} required validation={{ email: true }} />
            </Grid>
            <FileUpload
                css={2}
                mt={20}
                inputSocket={formPlug}
                field='eid'
                label="Emirates ID"
                dragNdrop
                buttonLabel="Drag and Drop 'Emirates ID' or click here"
                maxFileSizeMB={50}
                accept={{ items: ["pdf"], message: 'Only pdf format accepted' }}
                showPreview
                icon={'/assets/icons/upload.svg'}
                required
            />
            <FileUpload
                css={3}
                mt={20}
                inputSocket={formPlug}
                field='attachments'
                label="Supporting Documents"
                limited={3}
                maxFileSizeMB={50}
                accept={{ items: ["jpg", "jpeg", "png", "pdf", "doc", "docx"], message: 'Only jpg, jpeg, png, pdf, doc and docx formats accepted' }}
                showPreview
                icon={'/assets/icons/plus_black.svg'}
                multiple
            />
            <Buttons end mt={20}>
                <Button content='Cancel' css={1} onClick={() => { M.Handler('cancel') }} />
                <Button content='Submit' css={1.1} onClick={async () => { await validate() && M.Handler('submit', { m_inputs: inputs }) }} />
            </Buttons>
        </div>
    )
}
// **************************************************************************************************************************************
export const ResetPassword = ({ M }) => {
    const { t } = useTranslate()
    const passwordRef = useRef()
    const confirmPasswordRef = useRef()
    usePasswordProtect({ passwordRef })
    usePasswordProtect({ passwordRef: confirmPasswordRef })
    const { formPlug, inputs, validate, setErrors } = useForm()
    async function submitHandler() {
        if (!await validate()) return
        if (inputs?.pwd !== inputs?.confirm_pwd) {
            setErrors(s => ({ ...s, confirm_pwd: 'Passwords do not match' }))
            return
        }
        M.Handler('submit', { m_inputs: inputs })
    }
    return (
        <div>
            <h3>{t('Reset Password')}</h3>
            <Input css={1} label='New Password' ref={passwordRef} field='pwd' type={'password'} inputSocket={formPlug} validation={{ minChar: { length: 8 } }} required mb={15} />
            <Input css={1} label='Confirm Password' ref={confirmPasswordRef} field='confirm_pwd' type={'password'} inputSocket={formPlug} required mb={15} />
            <Buttons end mt={20}>
                <Button content='Cancel' css={1} onClick={() => { M.Handler('cancel') }} />
                <Button content='Submit' css={1.1} onClick={submitHandler} />
            </Buttons>
        </div>
    )
}
// **************************************************************************************************************************************
export const ActionConfirmation = ({ M, data }) => {
    const { t, lang, _lang } = useTranslate()
    return (
        <div id="ActionConfirmation" lang={lang}>
            <div>
                <p className="st">{t('The request is already in processing state set by user')}</p>
                <span className="highlight">{data['username' + _lang] + '.'}</span>
                <p className="st">{t('If you continue, you will be the assignee of the request') + '.'}</p>
                <p>{t('Do you want to continue ?')}</p>
            </div>
            <Buttons center mt={20}>
                <Button css={1.1} content="Yes" onClick={() => M.Handler('Yes')} />
                <Button css={1} content="No" onClick={() => M.Handler('No')} />
            </Buttons>
        </div>
    )
}
// **************************************************************************************************************************************
export const DismissMessage = ({ M, data }) => {
    const { t, lang } = useTranslate()
    return (
        <div id="DismissMessage" lang={lang}>
            <p>{t(data.message)}</p>
            <Buttons center mt={20}>
                <Button css={1.1} content="Go back to requests page" width={'auto'} onClick={() => M.Handler('toRequests')} />
                <Button css={1} content="Stay back in this request" width={'auto'} onClick={() => M.Handler('stayBack')} />
            </Buttons>
        </div>
    )
}
// **************************************************************************************************************************************
export const Add_Edit_IncidentType = ({ M, m_inputs, isEdit }) => {
    const { t } = useTranslate()
    const { formPlug, validate, inputs, setInputs } = useForm(m_inputs?.incidentTypeDetails)
    const [subTypes, setSubTypes] = useState(m_inputs?.subTypeDetails || [])
    async function submitHandler() {
        if (!await validate()) return
        M.Handler('submit', { m_inputs: { ...inputs, subTypes, deleted: m_inputs?.subTypeDetails.filter(i => !subTypes.find(j => j._id === i._id)) } })
    }
    return (
        <div id="Add_Edit_IncidentType">
            <h3>{t(`${isEdit ? 'Edit' : 'Add'} Incident Type`)}</h3>
            <Input css={1} mb={20} placeholder='Enter Incident Type' label='Incident Type [En]' field='incident_type_en' required inputSocket={formPlug} />
            <Input css={1} placeholder='Enter Incident Type' label='Incident Type [Ar]' field='incident_type_ar' required inputSocket={formPlug} />
            <hr />
            <h6>Sub Types</h6>
            <ArrayCollector
                value={subTypes}
                elements={[
                    { key: 'sub_incident_type_en', title: 'English' },
                    { key: 'sub_incident_type_ar', title: 'Arabic' },
                ]}
                onChange={(va) => { setSubTypes(va) }}
            />
            <Buttons end mt={20}>
                <Button content='Cancel' css={1} onClick={() => M.Handler('cancel')} />
                <Button content='Submit' css={1.1} onClick={submitHandler} />
            </Buttons>
        </div>
    )
}
// **************************************************************************************************************************************


function modalQuestionCorrector(status, t) {
    switch (status) {
        case 'Not Responded': return `${t("change status to")} ${t("Not Responded")}`
        case 'Processing': return `${t("change status to")} ${t("Processing")}`
        case 'Completed': return `${t("change status to")} ${t("Completed")}`
        default: return t(status)
    }
}