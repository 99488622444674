import React, { createContext, useEffect, useState } from 'react'
import './index.scss'
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import { Provider as ReduxProvider } from 'react-redux'
import { configureStore, createSlice } from '@reduxjs/toolkit'
import { clearAppData } from './App';
import ReactWaterMark from "react-watermark-component";
import config from '../common/config';
import { toast, ToastContainer } from 'react-toastify';

export let AppLoggedIn = null

export let AppLoggedOut = null

export let storageUpdate = null

export let setApiHeaders = null

export let setWaterMarkText = null

export let translationData = null

const slice = createSlice({
    name: 'reduxStore',
    initialState: { data: {}, loader: {}, payload: {}, system: {} },
    reducers: {
        resetStore: (state) => {
            // return Object.fromEntries(Object.keys(state).map(key => {
            //     // return [key, Array.isArray(state[key]) ? [] : null]
            // }))
            return { data: {}, loader: {}, payload: {}, system: {} }
        },
        updateStore: (state, action) => {
            return { ...state, ...action.payload }
        },
        updateRedux: (state, action) => {
            state[action.payload.key] = action.payload.value
        },
        updateData: (state, action) => {
            state.data[action.payload.key] = action.payload.value
        },
        updateLoader: (state, action) => {
            state.loader[action.payload.key] = action.payload.value
        },
        updatePayload: (state, action) => {
            state.payload[action.payload.key] = action.payload.value
        },
        updateSystem: (state, action) => {
            state.system[action.payload.key] = action.payload.value
        }
    }
})

const store = configureStore({
    reducer: slice.reducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false, })
})

export const { updateRedux, updateData, updateLoader, updatePayload, updateSystem } = slice.actions

export function storeUpdate(obj) {
    if (obj instanceof Function) {
        let [[c, d]] = Object.entries(obj(store.getState()))
        if (c) {
            store.dispatch(updateRedux({ key: c, value: d }))
        }
    } else {
        const key = Object.keys(obj)
        store.dispatch(updateRedux({ key: key, value: obj[key] }))
    }
}

export const CommonContext = createContext()

export const axiosInstance = axios.create()

axiosInstance.interceptors.request.use((config) => {
    let token = localStorage.getItem("token")
    let csrf = localStorage.getItem("csrf")
    token && (config.headers.Authorization = `Bearer ${token}`)
    csrf && (config.headers['x-csrf-token'] = csrf)
    config.headers.tmz = new Date().getTimezoneOffset() * -1
    return config
})

let networkError = false
axiosInstance.interceptors.response.use(
    (response) => {
        return response
    },

    (error) => {
        if (error.code === "ERR_NETWORK" && !networkError) {
            networkError = true
            setTimeout(() => {
                toast.error('Network Error', { position: 'bottom-center' })
            }, 1000);
        }
        else if (error.response.status === 401) {
            AppLoggedOut()
            networkError = false
            // window.location.reload()
            throw error
        } else {
            networkError = false
            throw error
        }
    }
)

export const classMaker = (className = '', cssNo, item, ...arr) => {
    let namespaces = {
        ActionButtons: 'acb',
        Actions: 'act',
        AttachmentsEyeView: 'aev',
        AttachmentsPreview: 'atp',
        Breadcrumb: 'brc',
        Button: 'btn',
        Card: 'crd',
        Checkbox: 'cbx',
        Choose: 'cho',
        CommentsViewer: 'tcv',
        DatePick: 'dtp',
        DateViewer: 'dtv',
        Div: 'div',
        EntriesCounter: 'enc',
        Files: 'fls',
        FileUpload: 'fup',
        FilterBox: 'flb',
        Flex: 'flx',
        FooterButtons: 'ftb',
        Grid: 'grd',
        Image: 'img',
        InfoIcon: 'ifi',
        Input: 'inp',
        LabelValue: 'lbv',
        Layout: 'lyt',
        List: 'lis',
        Logs: 'log',
        Modal: 'mdl',
        ModalContent: 'mdc',
        NavBar: 'nvb',
        Notifications: 'ntf',
        Page_Body: 'pgb',
        Page_Footer: 'pgf',
        Page_Header: 'pgh',
        Page: 'pag',
        Pagination: 'pgn',
        ProgressBar: 'pgb',
        RadioButtons: 'rdb',
        Section: 'sct',
        Select: 'slc',
        Slider: 'sld',
        StatusBox: 'stb',
        SupportedFormats: 'spf',
        Table: 'tbl',
        TextArea: 'txr',
        Title: 'ttl',
        TitleBar: 'tlb',
    }

    let classes = (cssNo
        ? `css-${namespaces[item]}` + String(cssNo).split('.').reduce((acc, crr, i) => acc + ' ' + Array(i + 1).fill("_").join('') + crr, '')
        : (cssNo === undefined
            ? `css-${namespaces[item]}`
            : ''))
        + (className
            ? ' ' + className
            : '')

    return classes
}

export default function Provider({ children }) {

    const [language, setLanguage] = useState(localStorage.getItem('lang') || 'en')
    const [isOnline, setIsOnline] = useState(navigator.onLine)
    const [appLoader, setAppLoader] = useState(0)
    const [pageNotFound, setPageNotFound] = useState(false)
    const [data, setData] = useState()
    const [navbarObj, setNavbarObj] = useState({})

    useEffect(() => {
        window.addEventListener('online', networkHandler)
        window.addEventListener('offline', networkHandler)
        return () => {
            window.removeEventListener('online', networkHandler);
            window.removeEventListener('offline', networkHandler);
        }
    }, [])

    useEffect(() => {
        localStorageGetter()
        window.addEventListener('beforeunload', localStorageSetter)
        return () => {
            window.removeEventListener('beforeunload', localStorageSetter)
        }
    }, [data])

    function localStorageGetter() {
        const res = JSON.parse(localStorage.getItem('commonPersist'))
        if (res) {
            if (!data && res) {
                setData(res)
                store.dispatch(slice.actions.updateStore(res.storage))
            }
            localStorage.removeItem('commonPersist')
        }
    }

    function localStorageSetter() {
        localStorage.setItem('commonPersist', JSON.stringify(data || {}))
    }

    function networkHandler(e) {
        setIsOnline(e.type === 'online')
    }

    translationData = (data) => {
        setData(s => ({ ...(s || []), translationData: data }))
    }

    AppLoggedIn = () => {
        setData(s => ({ ...s, timer: new Date() }))
    }

    AppLoggedOut = () => {
        localStorage.clear()
        setData()
        clearAppData()
        store.dispatch(slice.actions.resetStore())
    }

    setApiHeaders = (obj) => {
        if (obj instanceof Function) {
            let d = obj(data?.apiHeaders) || data?.apiHeaders
            d && setData(s => ({ ...s, apiHeaders: { ...s?.apiHeaders, ...d } }))
        } else {
            setData(s => ({ ...s, apiHeaders: { ...s?.apiHeaders, ...obj } }))
        }
    }

    storageUpdate = (obj) => {
        if (obj instanceof Function) {
            let [[c, d]] = Object.entries(obj(data?.storage))
            if (c) {
                setData(s => ({ ...s, storage: { ...s?.storage, [c]: d } }))
                store.dispatch(updateRedux({ key: c, value: d }))
            }
        } else {
            const key = Object.keys(obj)
            setData(s => ({ ...s, storage: { ...s?.storage, [key]: obj[key] } }))
            store.dispatch(updateRedux({ key: key, value: obj[key] }))
        }
    }

    setWaterMarkText = (text) => {
        setData(s => ({ ...s, waterMarkText: text || '' }))
    }

    const errors = {
        isOffline: !isOnline,
        isLoading: appLoader,
        is404: pageNotFound
    }

    const contextValues = {
        language,
        setLanguage,
        apiHeaders: data?.apiHeaders,
        setAppLoader,
        errors,
        setPageNotFound,
        setNavbarObj,
        navbarObj,
        translationData: data?.translationData
    }

    return (
        <ReduxProvider store={store}>
            <CommonContext.Provider value={contextValues}>
                {
                    data?.waterMarkText
                        ? <ReactWaterMark waterMarkText={data.waterMarkText}>{children}</ReactWaterMark>
                        : children
                }
                <ToastContainer />
            </CommonContext.Provider>
        </ReduxProvider >
    )
}