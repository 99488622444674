import React, { useContext, useEffect, useState } from 'react'
import './Input.scss'
import { v4 } from 'uuid'
import useTranslate from '../../../_common/_Hooks/useTranslate'
import { classMaker } from '../..'
import __config from '../../../common/config'
import usePatternMasking from '../../_Hooks/usePatternMasking'
import { ModalContentContext } from '../ModalContent/ModalContent'
import Hint from '../Hint/Hint'

let validationObj = {
  minChar: { message: '', condition: '', length: '' },
  maxChar: { message: '', condition: '', length: '' },
  minNumber: { message: '', condition: '', number: '' },
  maxNumber: { message: '', condition: '', number: '' },
  email: { message: '', condition: '' },
  alphabetsOnly: { message: '', condition: '', more: '' },
  alphaNumeric: { message: '', condition: '', more: '' },
  phone: { message: '', condition: '', digits: '' },
  pattern: { message: '', value: '' }
}

const Input = React.forwardRef(({ className, css, type, label, value, field, noField = false, noForm = false, labelIcon, onChange, onSubmit, onFocus, onIconClick, onIconClick2, autocomplete, placeholder, inputClassName, disabled, required, icon, icon2, reverse, onLabelIconClick, width, error, checked, showCloseBtn, dependency = [], inputSocket, validation = validationObj, readOnly, dir, onBlur, span, condition, clearOnHide, hideStar, onKeyDown, mb, mt, mr, ml, labelGap, iconHint, icon2Hint }, ref) => {

  const { formPlug } = useContext(ModalContentContext) || {}
  inputSocket = inputSocket || formPlug

  let connector = inputSocket?.connector
  error = error || inputSocket?.errors
  value = value || inputSocket?.inputs
  onChange = onChange || inputSocket?.inputHandler

  let pattern = typeof validation.pattern === 'string' ? validation.pattern : validation.pattern?.value

  let uuid = v4()
  const { t } = useTranslate()

  const [inValue, setInValue] = useState('')
  const [inLabel, setInLabel] = useState('')
  const [inPlaceholder, setInPlaceholder] = useState('')
  const [inError, setInError] = useState('')

  const { PM_changeHandler, PM_keyDownHandler, PM_value } = usePatternMasking({
    pattern,
    message: validation.pattern?.message,
    value: inValue,
    onChange: (e, options) => changeHandler(e, options),
    onKeyDown: keyDownHandler,
    type,
    label
  })

  useEffect(() => {
    setInValue(() => {
      let res = ''
      if (typeof value === 'object' && value?.[field] !== undefined) res = value?.[field] || ''
      else if (typeof value === 'object' && value?.[field] === undefined) res = ''
      else if (value !== undefined) res = value || ''
      return res
    })
    if (!field && !noField) setInError('Please add field')
    placeholder && setInPlaceholder(placeholder)
    label && setInLabel(label)
    return () => {
      setInValue('')
      setInPlaceholder('')
      setInLabel('')
    }
  }, [value, value?.[field], placeholder, label, ...dependency])

  useEffect(() => {
    if (condition === undefined || condition === true) {
      let obj = { key: field }
      if (required) obj.required = required
      if (ifObjectExist(validation.email)) obj.email = validation.email
      if (ifObjectExist(validation.phone)) obj.phone = validation.phone
      if (ifObjectExist(validation.minChar)) obj.minChar = validation.minChar
      if (ifObjectExist(validation.maxChar)) obj.maxChar = validation.maxChar
      if (ifObjectExist(validation.minNumber)) obj.minNumber = validation.minNumber
      if (ifObjectExist(validation.maxNumber)) obj.maxNumber = validation.maxNumber
      if (ifObjectExist(validation.alphabetsOnly)) obj.alphabetsOnly = validation.alphabetsOnly
      if (ifObjectExist(validation.alphaNumeric)) obj.alphaNumeric = validation.alphaNumeric
      connector && connector(obj)
      if (Object.keys(obj).length > 1 && !connector && !noForm) setInError("Please connect 'formPlug' to 'inputSocket'")
    }
    return () => { connector && connector({ key: field, clearValidation: true }) }
  }, [...(required && typeof required === 'object' ? Object.values(required) : [required])])

  useEffect(() => {
    return () => {
      condition && clearOnHide && connector && connector({ key: field, clearValidation: true, clearValue: true })
    }
  }, [condition])

  function ifObjectExist(obj = {}) {
    return Object.values(obj).join('').length > 0 || obj === true
  }

  function changeHandler(e, options) {
    if (type === 'number' && e.length > validation.maxNumber) return
    setInValue(e)
    onChange && onChange(e, field, options)
  }

  function keyDownHandler(e) {
    onKeyDown && onKeyDown(e)
    if (e.key === 'Enter') {
      onSubmit && onSubmit(inValue, field)
    }
    if (type === 'number' && !(
      (e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode >= 96 && e.keyCode <= 105) ||
      (e.keyCode >= 112 && e.keyCode <= 160) ||
      [8, 9, 107, 37, 39].includes(e.keyCode) ||
      (e.keyCode === 86 && e.ctrlKey === true) ||
      (e.keyCode === 67 && e.ctrlKey === true) ||
      (e.keyCode === 46)
    )) {
      e.preventDefault()
    }
  }

  function pasteHandler(e) {
    if (type === 'number' && isNaN(Number(e.clipboardData.getData('text/plain')))) {
      e.preventDefault()
    }
  }

  function hintHandler(hint, elem) {
    return hint
      ? <Hint hint={hint}>{elem}</Hint>
      : elem
  }

  const isError = inError || (typeof error === 'object' ? error?.[field] : error)

  if (!condition && condition !== undefined) return null

  return (
    <span ref={ref} id='Input' className={classMaker(className, css, 'Input') + (reverse ? ' reverse' : '') + (pattern ? ' pattern' : '') + (isError ? ' error' : '')} style={{ width: width, marginBottom: mb, marginTop: mt, marginRight: mr, marginLeft: ml }}>
      {inLabel && <label htmlFor={uuid} style={{ marginBottom: labelGap }}>{t(inLabel)}{(required?.condition !== undefined ? required?.condition : required) && !hideStar && <em>*</em>}{labelIcon && <img width={15} onClick={onLabelIconClick} className='label_icon' src={labelIcon} />}</label>}
      <span className={'icon_wrap'}>
        <input
          id={uuid}
          type={type === 'password' ? 'password' : 'text'}
          inputMode={type === 'number' ? 'numeric' : 'none'}
          className={inputClassName}
          value={pattern ? PM_value : inValue}
          checked={checked && checked}
          placeholder={t(inPlaceholder) || pattern || t(__config.Input_placeholder)}
          onChange={PM_changeHandler}
          onKeyDown={PM_keyDownHandler}
          onPaste={pasteHandler}
          disabled={disabled}
          readOnly={readOnly}
          dir={dir}
          onBlur={(e) => onBlur && onBlur(e.target.value, field)}
          onFocus={(e) => onFocus && onFocus(e.target.value, field)}
          autoComplete={autocomplete ? "" : "new-password"}
        />
        {icon && hintHandler(iconHint, <img className={'input_icon'} src={icon} onClick={() => onIconClick && onIconClick(inValue, field)} style={{ cursor: onIconClick ? 'pointer' : 'auto' }} />)}
        {icon2 && hintHandler(icon2Hint, <img className={'input_icon icon2'} src={icon2} onClick={() => onIconClick2 && onIconClick2(inValue, field)} style={{ cursor: onIconClick2 ? 'pointer' : 'auto' }} />)}
        {showCloseBtn && inValue && <span className='close_btn' onClick={() => { setInValue(''); onChange && onChange('', field) }}>✖</span>}
      </span>
      {isError && <small id='error' className='error'>{inError || (typeof error === 'object' ? t(error?.[field] || '') : t(error))}</small>}
    </span >
  )
})
Input.displayName = 'Input'

export default Input

{/*

<Input
  label=''
  type=''
  value=''
  field=''
  onChange={() => { }}
  onSubmit={() => { }}
  onIconClick={() => { }}
  placeholder=''
  icon=''
  className=''
  reverse
  width=''
  height=''
  error=''
  checked=''
  showCloseBtn
/>

*/}