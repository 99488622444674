import React from 'react'
import Page from '../../_common/_components/Page/Page'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import TitleBar from '../../_common/_components/TitleBar/TitleBar'
import LabelValue from '../../_common/_components/LabelValue/LabelValue'
import Grid from '../../_common/_components/Grid/Grid'
import ActionButtons from '../../_common/_components/ActionButtons/ActionButtons'
import useFetch from '../../_common/_Hooks/useFetch'
import { useNavigate, useParams } from 'react-router-dom'
import Table from '../../_common/_components/Table/Table'
import Actions from '../../_common/_components/Actions/Actions'
import DateViewer from '../../_common/_components/DateViewer/DateViewer'
import FooterButtons from '../../_common/_components/FooterButtons/FooterButtons'
import Button from '../../_common/_components/Button/Button'
import { useSelector } from 'react-redux'
import Files from '../../_common/_components/Files/Files'
import { CALL_BASE_URL, IMG_BASE_URL } from '../../config'
import axios from 'axios'
import List from '../../_common/_components/List/List'
import useTranslate from '../../_common/_Hooks/useTranslate'
import useModal from '../../_common/_Hooks/useModal'
import { Confirm } from '../../common/Modals'
import VoicePlayer from '../../components/VoicePlayer/VoicePlayer'

export default function Volunteers_detail() {

    const { id } = useParams()
    const navigate = useNavigate()
    const { t, _lang } = useTranslate()
    const statuses = useSelector(s => s.statuses)
    const user = useSelector(s => s.user)

    const m_confirm = useModal(<Confirm />)

    // *********************************************************************************************************************
    const { res: actions_res } = useFetch({
        endpoint: 'common/getAllStatus',
        method: 'get',
        type: 'raw',
        selfCall: true
    })
    // *********************************************************************************************************************
    const { res: details_res } = useFetch({
        endpoint: 'volunteer/getVolunteerDetails',
        method: 'get',
        type: 'params',
        selfCall: { id }
    })
    // *********************************************************************************************************************
    const { res: history_res } = useFetch({
        endpoint: 'volunteer/getVolunteerHistory',
        method: 'post',
        type: 'raw',
        selfCall: { id }
    })
    // *********************************************************************************************************************
    const { req: takeAction } = useFetch({
        endpoint: 'volunteer/approveOrReject',
        method: 'post',
        type: 'raw',
        toast: true
    })
    // *********************************************************************************************************************
    const actions = actions_res?.data
    const details = details_res?.data
    const relatedEvents = details_res?.data.history
    const history = history_res?.data
    // *********************************************************************************************************************

    async function actionHandler(action) {
        const { cancel, close } = await m_confirm.show({ action: action.action_status })
        if (cancel || close) return
        takeAction({
            id: id,
            status: action._id
        }, () => {
            navigate('/volunteer/volunteers')
        })
    }


    function callHandler(number, ticket_no) {
        const formdata = new FormData()
        formdata.append('phone_number', number.replace('+','00'))
        formdata.append('agent_id', user?.phone_number)
        formdata.append('ticket_id', ticket_no)
        formdata.append('agent_name', user?.employee_id)
        formdata.append('type', 'volunteer_with_us')
        axios.post(CALL_BASE_URL, formdata)
    }

    return (
        <Page>

            <Page.Header>
                <Title title='Volunteer Detail' />
                <Breadcrumb />
            </Page.Header>

            <Page.Body css={1}>
                <TitleBar css={1} title={'Details'}>
                    <Grid columns={5}>
                        <LabelValue label='First Name' value={details?.first_name} />
                        <LabelValue label='Last Name' value={details?.last_name} />
                        <LabelValue css={1} label='Phone' value={details?.phone_number ? ((details?.customer_contact_code || '+971') + ' ' + details?.phone_number) : '-'} isHyperlink valueIcon={'/assets/icons/call-green-round.svg'} dir={'ltr'}
                            onIconClick={() => callHandler((details?.customer_contact_code || '+971') + details?.phone_number, details?._id)} valueIconHint='Call Volunteer' />
                        <LabelValue label='Email' value={details?.email} />
                        <LabelValue label='Source' value={details?.request_type} className='volunteer_source' />
                        <LabelValue label='IP' value={details?.ip} />
                        <LabelValue label='Device ID' value={details?.udid} />
                    </Grid>
                    <hr />
                    <Grid columns={5} mt={20}>
                        {/* <LabelValue label='Emirates ID' value={''} />
                        <LabelValue mt={20} label='Supporting Documents ( PDF , JPG , PNG )' span={2} value={''} /> */}
                        <Files label={'Emirates ID'} value={details?.eid.map(item => ({ url: IMG_BASE_URL + 'volunteer/' + item, name: item }))} />
                        <Files label={`${t('Supporting Documents')} ( PDF , JPG , PNG )`} span='fill' value={details?.attachments.map(item => ({ url: IMG_BASE_URL + 'volunteer/' + item, name: item }))} />
                    </Grid>
                </TitleBar>

                {relatedEvents?.length > 0 && <TitleBar css={1} title='Assigned Events'>
                    <Table
                        css={1.1}
                        _css={{ search: 2, pagination: 1, entries: 1 }}
                        titles={[
                            { title: 'Title', key: 'title' },
                            { title: 'Date From', key: 'event_from' },
                            { title: 'Date To', key: 'event_to' },
                            { title: 'Primary Volunteers', key: 'primary_volunteers' },
                            { title: 'Backup Volunteers', key: 'backup_volunteers' },
                            { title: 'Role', key: 'role' },
                            { title: 'Action', key: 'action' }
                        ]}
                        content={relatedEvents?.map((r) => {
                            return {
                                _id: r.event._id,
                                title: r.event.title,
                                event_from: <DateViewer dateTime={r.event.event_from} />,
                                event_to: <DateViewer dateTime={r.event.event_to} />,
                                primary_volunteers: r.event.primary_volunteers.length,
                                backup_volunteers: r.event.backup_volunteers.length,
                                role: r.is_primary ? 'Primary' : r.is_backup ? 'Backup' : '',
                                action: <Actions buttons={{ addVl: '/assets/icons/volunteer.svg', view: '/assets/icons/eye.svg', }}
                                    visibility={{
                                        addVl: new Date(r.event.event_to) - new Date() > 0
                                    }}
                                    tooltip={{ addVl: 'Assign Volunteer' }}
                                    onAddVl={() => { navigate('/volunteer/events/assign_volunteers/' + r.event._id) }}
                                    onView={() => { navigate('/volunteer/events/event_detail/' + r.event._id) }}
                                />,
                            }
                        })}
                        onRowClick={(e) => { navigate('/volunteer/events/event_detail/' + e._id) }}
                        hideFilters
                    />
                </TitleBar>}

                {history?.length > 0 && <TitleBar css={1} title={'History'}>
                    <List css={1} items={history}>
                        {
                            (item) => {
                                let hby = item.handled_by?.['first_name' + _lang]
                                return (
                                    <>
                                        <List.Bullet src={'/assets/icons/history2.svg'} />
                                        <div>
                                            <p>{item['title' + _lang]}</p>
                                            {/* {hby && <p>{hby} : Handled By</p>}
                                            <p>Updated At : {<DateViewer dateTime={item.updatedAt} />}</p> */}
                                            {hby && <LabelValue label='Handled By' value={hby} ml={60} mt={20} />}
                                            <LabelValue label='Updated At' value={<DateViewer dateTime={item.updatedAt} />} mt={20} />
                                            {item?.voice_file && <VoicePlayer file={item?.voice_file} requestId={id} type='volunteer_with_us' />}
                                        </div>
                                    </>
                                )
                            }
                        }
                    </List>
                </TitleBar>}

            </Page.Body>

            <FooterButtons>
                <Button right css={1} content="Back" icon="/assets/icons/back.svg" reverse iconWidth="15px" onClick={() => navigate(-1)} />
                {
                    details?.status.handle_key === 'to_be_reviewed' &&
                    <>
                        <Button left content="Reject" css={1} onClick={() => { actionHandler(statuses.rejected) }} />
                        <Button left content="Approve" css={1} onClick={() => { actionHandler(statuses.approved) }} />
                    </>
                }
            </FooterButtons>

        </Page>
    )
}