import React, { useState } from 'react'
import './ModalsContainer.scss'
import ModalComponent from '../ModalComponent/ModalComponent'
import CustomModals from '../../../common/Modals'

let Modals = {}
let timeOutId
export const ModalContext = React.createContext()
function ModalsContainer() {

    const [show, setShow] = useState(false)
    const [resolver, setResolver] = useState(null)
    const [inputs, setInputs] = useState({})
    const [hideButton, setHideButton] = useState()
    const [DynamicModal, setDynamicModal] = useState()
    const [currKey, setCurrKey] = useState('')

    function resolveHandler(type, data) {
        resolver.run({ [type]: true, m_inputs: data })
        setShow(false)
    }

    let mods = {
        ...CustomModals({ m_action: resolveHandler, setShow })
    }

    Object.keys(mods).forEach((key) => {
        if (!(mods[key] instanceof Function)) return
        const DefinedModal = mods[key]
        Modals[key] = ({ m_inputs, m_condition, ...restArgs } = {}) => {
            let showModal = (m_condition === undefined ? true : m_condition)
            if (showModal) {
                setShow(true)
                setInputs(m_inputs || {})
                setCurrKey(key)
                setDynamicModal(<DefinedModal {...restArgs} />)
                mods[key + '_options']?.showCloseButton !== undefined && setHideButton(mods[key + '_options'].showCloseButton)
            }
            return new Promise((resolve) => {
                showModal
                    ? setResolver({ run: resolve })
                    : resolve({ m_inputs: m_inputs || {} })
            })
        }
    })

    function autoHider(outTime) {
        if (!timeOutId) {
            timeOutId = setTimeout(() => {
                if (show) {
                    setShow(false)
                    clearTimeout(timeOutId)
                    timeOutId = null
                }
            }, outTime === true ? 3000 : outTime)
        }
    }

    function closeBtnHideHandler(value) {
        setHideButton(value)
    }

    function setShowHandler(e) {
        setShow(e)
        timeOutId && clearTimeout(timeOutId)
        timeOutId = null
    }

    const closeButtonStatus = hideButton === undefined ? mods.showCloseButton : hideButton

    return (
        <ModalComponent show={show} setShow={setShowHandler} onHide={setShowHandler} className={currKey}>
            <ModalContext.Provider value={{ handler: resolveHandler, m_inputs: inputs, autoHider, closeBtnHideHandler, setShow, showCloseBtn: closeButtonStatus }}>
                {
                    DynamicModal
                }
            </ModalContext.Provider>
        </ModalComponent >
    )
}

export { ModalsContainer, Modals }