import React, { Children, cloneElement, useEffect, useState } from 'react'
import './FilterBox.scss'
import { classMaker } from '../..'
import Button from '../Button/Button'
import Flex from '../Flex/Flex'
import icon_filter from './flb_filter.svg'
import icon_export from './flb_export.svg'
import config from '../../../common/config'

let _cssOptions = {
  Select: null,
  DatePick: null,
  Input: null,
  Search_Btn: null,
  Clear_Btn: null,
}
let _placeholderOptions = {
  Select: null,
  Input: null,
}

export default function FilterBox({ className, css, _css = _cssOptions, _placeholder = _placeholderOptions, children, showCloseBtns, filterHandler, filterData, dependency }) {

  const [state, setState] = useState({})
  const [toggle, setToggle] = useState(true)

  useEffect(() => {
    let filters = Object.fromEntries(Object.entries(filterData || {}).filter(([a, b]) => Children.map(children, c => c.props.field).includes(a)))
    setState(filters)
  }, [filterData])

  useEffect(() => {
    setToggle(true);
  }, dependency)

  function changeHandler(value, key, filter_vl) {
    setState(s => ({ ...s, [key]: filter_vl ? value[filter_vl] : value }))
  }

  function clearHandler() {
    setState({})
    filterHandler && filterHandler('clearSearch')
  }

  return (
    <div id='FilterBox' className={classMaker(className, css, 'FilterBox') + (toggle ? ' toggle_on' : ' toggle_off')}>
      <Flex columns={6} gap={10} _minWidth={230} className={'mt-3'}>
        {
          Children.map(children, child => {
            let type = child.type.displayName
            let childProp = child.props
            if (('condition' in childProp) && !childProp.condition) return
            return cloneElement(
              child,
              {
                onChange: (value, key) => {
                  // value = !isNaN(Date.parse(value))?
                  changeHandler(value, key, childProp.content?.vl)
                  childProp?.onChange && childProp?.onChange(value, key, state)
                },
                maxDate: (childProp?.maxDate) instanceof Function
                  ? childProp?.maxDate(state)
                  : childProp?.maxDate,
                minDate: (childProp?.minDate) instanceof Function
                  ? childProp?.minDate(state)
                  : childProp?.minDate,
                css: _css[type],
                className: 'elem ' + (childProp.className || ''),
                placeholder: childProp.placeholder || _placeholder[type],
                value: childProp.value || (childProp.field && state?.[childProp.field]),
                showCloseBtn: showCloseBtns
              }
            )
          })
        }
        <span className='buttons'>
          {<Button width={70} css={_css.Search_Btn} icon={'../../assets/icons/search.svg'} className="search_button" content={""} iconWidth={20} onClick={() => filterHandler && filterHandler('filterBox', state)} />}
          {Object.values(state).some(i => i) && <Button css={_css.Clear_Btn} className="sm me-2 close_button" onClick={() => clearHandler()} content="✖" />}
        </span>
      </Flex>
      <span className='more_buttons'>
        {filterData.export && <Button css={_css.Export_Btn} className='export_button' onClick={() => filterHandler.export()} content='Export' icon={config.FilterBox_export_icon || icon_export} reverse />}
        <img className='toggle_icon' src={icon_filter} onClick={() => setToggle(s => !s)} alt="" />
      </span>
    </div>
  )
}