import React from 'react'
import Page from '../../_common/_components/Page/Page'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import FilterBox from '../../_common/_components/FilterBox/FilterBox'
import DatePick from '../../_common/_components/DatePick/DatePick'
import Table from '../../_common/_components/Table/Table'
import useTranslate from '../../_common/_Hooks/useTranslate'
import useFilter from '../../_common/_Hooks/useFilter'
import Button from '../../_common/_components/Button/Button'
import { useNavigate } from 'react-router-dom'
import Actions from '../../_common/_components/Actions/Actions'
import useFetch from '../../_common/_Hooks/useFetch'
import DateViewer from '../../_common/_components/DateViewer/DateViewer'
import { Modals } from '../../_common/_components/ModalsContainer/ModalsContainer'

export default function Events_listing() {

    const { _lang } = useTranslate()
    const navigate = useNavigate()

    // *********************************************************************************************************************
    const { req: getEvents, res: events_res } = useFetch({
        endpoint: 'event/getEvent',
        method: 'post',
        type: 'raw',
    })
    // *********************************************************************************************************************
    const { req: deleteEvent } = useFetch({
        endpoint: 'event/deleteEvent',
        method: 'delete',
        type: 'params',
        toast: true
    })
    // *********************************************************************************************************************
    const { req: exportEvents } = useFetch({
        endpoint: 'event/getEventExport',
        method: 'post',
        type: 'raw',
        download: true
    })
    // *********************************************************************************************************************
    const events = events_res?.data
    const pageDetails = events_res?.page
    // *********************************************************************************************************************

    const { filterData, filterHandler, F_navigate } = useFilter({
        initialFilter: { page: 0, perPage: 10 },
        Fetch: getEvents,
        Export: exportEvents
    });

    async function deleteHandler(id) {
        const { cancel } = await Modals.Confirm({ action: 'Delete' })
        if (cancel) return
        deleteEvent(id, () => {
            getEvents(filterData)
        })
    }

    return (
        <Page>

            <Page.Header>
                <Title />
                <Breadcrumb />
                <Button content='Add Event' css={1.1} icon={'/assets/icons/plus.svg'} reverse onClick={() => navigate('add_event')} />
            </Page.Header>

            <Page.Body css={1} >
                <FilterBox
                    _css={{ Select: 1, DatePick: 1, Input: 1, Search_Btn: 1.1, Clear_Btn: 1.2, Export_Btn: 3 }}
                    _placeholder={{ Select: 'Select', Input: 'Type in input' }}
                    showCloseBtns
                    filterHandler={filterHandler}
                    filterData={filterData}
                >
                    <DatePick label='Start Date' field='event_from' maxDate={(e) => e.event_to} />
                    <DatePick label='End Date' field='event_to' minDate={(e) => e.event_from} />
                </FilterBox>
                <Table
                    css={1}
                    _css={{ search: 2, pagination: 1, entries: 1 }}
                    titles={[
                        { title: 'Title', key: 'title', sort: true, width: 200 },
                        { title: 'Event From', key: 'event_from', sort: true },
                        { title: 'Event To', key: 'event_to', sort: true },
                        { title: 'Primary Volunteers', key: 'primary_volunteers', sort: true },
                        { title: 'Backup Volunteers', key: 'backup_volunteers', sort: true },
                        { title: 'Action', key: 'action' }
                    ]}
                    content={events?.map((r) => {
                        return {
                            _id: r._id,
                            title: r.title,
                            event_from: <DateViewer dateTime={r.event_from} />,
                            event_to: <DateViewer dateTime={r.event_to} />,
                            primary_volunteers: r.primary_volunteers_count,
                            backup_volunteers: r.backup_volunteers_count,
                            action: <Actions buttons={{ view: '/assets/icons/volunteer.svg', edit: '/assets/icons/edit.svg', delete: '/assets/icons/delete.svg' }}
                                visibility={{
                                    delete: new Date(r.event_from) - new Date() > 0 && !(r.primary_volunteers_count > 0 || r.backup_volunteers_count > 0),
                                    edit: new Date(r.event_to) - new Date() > 0
                                }}
                                onView={() => { F_navigate('event_detail/' + r._id) }}
                                onEdit={() => { F_navigate('edit_event/' + r._id) }}
                                onDelete={() => deleteHandler(r._id)}
                            />,
                        }
                    })}
                    onRowClick={(e) => { F_navigate('event_detail/' + e._id) }}
                    pageDetails={pageDetails}
                    filterHandler={filterHandler}
                    filterData={filterData}
                />
            </Page.Body>

        </Page>
    )
}
