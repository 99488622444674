import React from 'react'
import './Div.scss'
import { classMaker } from '../..'

export default function Div({ className, css, children, condition, mb, mt, mr, ml, }) {

    if (!condition && condition !== undefined) return null

    return (
        <div id='Div' className={classMaker(className, css, 'Div')} style={{ marginBottom: mb, marginTop: mt, marginRight: mr, marginLeft: ml }}>
            {children}
        </div>
    )
}
