import React, { useEffect, useRef, useState } from 'react'
import './EntriesCounter.scss'
import useTranslate from '../../../_common/_Hooks/useTranslate'
import { classMaker } from '../..'
import config from '../../../common/config'
import arrow_down from './arrow_down.svg'
import arrow_up from './arrow_up.svg'

export default function EntriesCounter({ className = '', css, value, onChange }) {

  const { t } = useTranslate()

  const [count, setCount] = useState(0)
  const intervalRef = useRef()

  useEffect(() => {
    value && setCount(Number(value))
    return () => setCount(0)
  }, [value])

  function changeHandler(event) {
    let e = Number(event.target.value || 0)
    if (e > 99) e = 99
    if (e < 1) e = 1
    setCount(e)
  }

  function blurHandler() {
    // onChange && onChange(count)
  }

  function keyDownHandler(e) {
    if (e.key === 'Enter') {
      onChange && onChange(count)
    }
  }

  function clickHandler(value) {
    setCount(s => {
      if ((s === 1 && value === -1) || (s === 99 && value === 1)) return s
      let result = s + value
      onChange && onChange(result)
      return result
    })
  }

  // function holdHandler(value) {
  //   if (intervalRef.current) return
  //   intervalRef.current = setInterval(() => {
  //     clickHandler(value)
  //   }, 150);
  // }

  // function leaveHolder() {
  //   if (intervalRef.current) {
  //     clearInterval(intervalRef.current)
  //     intervalRef.current = null
  //   }
  // }

  return (
    <span id='EntriesCounter' className={classMaker(className, css, 'EntriesCounter')}>
      {/* <p>{t("Show")}</p> */}
      <span className='wrap'>
        <input dir='ltr' type="number" min={0} value={count} onChange={changeHandler} onKeyDown={keyDownHandler} onBlur={blurHandler} />
        <span>
          <img src={arrow_up} onClick={() => clickHandler(1)}
          // onMouseDown={() => holdHandler(1)} onMouseUp={leaveHolder} onMouseLeave={leaveHolder} 
          />
          <img src={arrow_down} onClick={() => clickHandler(-1)}
          // onMouseDown={() => holdHandler(-1)} onMouseUp={leaveHolder} onMouseLeave={leaveHolder} 
          />
        </span>
      </span>
      {/* <p>{t("entries")}</p> */}
    </span>
  )
}