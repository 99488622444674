import React, { useEffect, useState } from 'react'
import './RadioButtons.scss'
import { v4 } from 'uuid'
import useTranslate from '../../_Hooks/useTranslate'
import { classMaker } from '../..'

let validationObj = {
  checkIfTrue: { message: '', condition: '' },
}

export default function RadioButtons({ className, css, label, buttons, required, field, value, inputSocket, error, onChange, multiChoice, condition, validation = validationObj, clearOnHide, hideStar, disabled, mb, mt, mr, ml }) {

  let connector = inputSocket?.connector
  error = error || inputSocket?.errors
  value = value || inputSocket?.inputs
  onChange = onChange || inputSocket?.inputHandler

  let uuid = v4()
  const { t } = useTranslate()

  const [state, setState] = useState(null)
  const [inError, setInError] = useState('')

  useEffect(() => {
    setState(() => {
      let res = ''
      if (typeof value === 'object' && !Array.isArray(value) && value?.[field] !== undefined) res = value?.[field]
      else if (typeof value === 'object' && !Array.isArray(value) && value?.[field] === undefined) res = null
      else if (value !== undefined) res = value
      return res
    })
    if (!field) setInError('Please add field')
  }, [value])

  useEffect(() => {
    if (condition === undefined || condition === true) {
      let obj = { key: field }
      if (required) obj.required = required
      if (ifObjectExist(validation.checkIfTrue)) obj.checkIfTrue = validation.checkIfTrue
      connector && connector(obj)
      if (Object.keys(obj).length > 1 && !connector) setInError("Please connect 'formPlug' to 'inputSocket'")
    }
    return () => { connector && connector({ key: field, clearValidation: true }) }
  }, [...(required && typeof required === 'object' ? Object.values(required) : [required]), condition])

  useEffect(() => {
    return () => {
      condition && condition && clearOnHide && connector && connector({ key: field, clearValidation: true, clearValue: true })
    }
  }, [condition])

  function ifObjectExist(obj = {}) {
    return Object.values(obj).join('').length > 0 || obj === true
  }

  function changeHandler(value) {
    setState(s => {
      let res = multiChoice
        ? s
          ? s?.includes(value)
            ? s?.filter(i => i !== value)
            : [...s, value]
          : [value]
        : value
      onChange && onChange(res, field)
      return res
    })
  }

  if (!condition && condition !== undefined) return null

  return (
    <span id='RadioButtons' className={classMaker(className, css, 'RadioButtons')} style={{ marginBottom: mb, marginTop: mt, marginRight: mr, marginLeft: ml }}>
      <span className='wrapper'>
        {label && <label className='label'>{t(label)}{(required?.condition !== undefined ? required?.condition : required) && !hideStar && <em>*</em>}</label>}
        <span className='buttons'>
          {
            buttons && buttons.map((btn, i) => {
              return (
                <span id={'btn' + i} className='radiobtn' key={i}>
                  <label htmlFor={uuid + btn.label}>{t(btn.label) || 'label'}</label>
                  <input
                    type={multiChoice ? "checkbox" : "radio"}
                    id={uuid + btn.label}
                    name={field}
                    checked={multiChoice ? (state?.includes(btn?.value) ?? false) : state === btn?.value}
                    onChange={(e) => changeHandler(btn.value)}
                    disabled={disabled}
                  />
                </span>
              )
            })
          }
        </span>
      </span>
      {(inError || (typeof error === 'object' ? error?.[field] : error)) && <small id='error'>{inError || (typeof error === 'object' ? t(error?.[field] || '') : t(error))}</small>}
    </span>
  )
}
RadioButtons.displayName = 'RadioButtons'

{/* 

<RadioButtons
  label='Select Gender'
  buttons={[
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' }
  ]}
  field='gender'
  value={value}
  error=''
  onChange={(value, key) => { }}
/>

*/}