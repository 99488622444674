import React from "react";
import "./LabelValue.scss";
import { classMaker } from '../..'
import useTranslate from "../../_Hooks/useTranslate";
import Hint from "../Hint/Hint";

export default function LabelValue({ className, css, label, value, dir, valueIconHint, isHyperlink, children, error, valueIcon, url, iconOnly, onLabelIconClick, reverse, labelIcon, iconWidth, iconHeight, span, onIconClick, onValueClick, mb, mt, mr, ml, mxWidth, width, iconHint }) {

  const { t } = useTranslate();

  const Value = () => {
    return (
      <p className={"value" + (reverse ? ' reverse' : '') + (onValueClick ? ' cl' : '')} dir={dir} onClick={onValueClick}>
        {valueIcon && (value || iconOnly) && hintHandler(valueIconHint, <img src={valueIcon} style={{ width: iconWidth, height: iconHeight }} className={"icon" + (onIconClick ? ' cl' : '')} onClick={(e) => { e.stopPropagation(); onIconClick && onIconClick() }} />)}
        {(value || iconOnly) || (children ? '' : "--")}
      </p>
    )
  }

  function hintHandler(hint, elem) {
    return hint
      ? <Hint hint={hint}>{elem}</Hint>
      : elem
  }

  return (
    <span id="LabelValue" className={classMaker(className, css, 'LabelValue')} style={{ marginBottom: mb, marginTop: mt, marginRight: mr, marginLeft: ml, maxWidth: mxWidth, width }}>
      {label && <p className="key">
        {t(label) || "label"}
        {labelIcon && hintHandler(iconHint, <img className={"label_icon" + (onLabelIconClick ? ' clickable' : '')} src={labelIcon} onClick={onLabelIconClick} />)}
      </p>}
      {(url && (value || iconOnly))
        ? <a href={url} target="_blank" className={isHyperlink ? "hyperlink" : ''} rel="noreferrer"> <Value /> </a>
        : <Value />
      }
      {children && <span className="inputs">{children}</span>}
      {error && <small id='error' className="error">{error}</small>}
    </span>
  )
}