import React, { useEffect, useState } from 'react'
import './FooterButtons.scss'
import { classMaker } from '../..'

export default function FooterButtons({ className = '', css, children }) {

  const [data, setData] = useState([])

  useEffect(() => {
    let left = []
    let right = []
    children && (!Array.isArray(children) ? [children] : children)
      .flatMap(item => String(item.type) === 'Symbol(react.fragment)' ? item.props.children : item)
      .forEach((item, i) => {
        if (item?.props?.right) right = [...right, item]
        else if (item?.props?.left) left = [...left, item]
      })
    setData({ rightButtons: right, leftButtons: left })
  }, [children])

  return (
    <div id='FooterButtons' className={classMaker(className, css, 'FooterButtons')}>
      <div>{data?.rightButtons}</div>
      <div>{data?.leftButtons}</div>
    </div>
  )
}