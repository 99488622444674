import React, { useContext, useEffect, useRef, useState } from 'react'
import Modal from '../../_common/_components/ModalComponent/ModalComponent'
import PopupContent from '../_components/PopupContent/PopupContent'

const PopupContext = React.createContext()
let timeOutId

export default function useModal(content) {

    const { setModalShow, setContent, setResolver, setPropsOnShow } = useContext(PopupContext)

    function show(data, { condition } = {}) {
        let showModal = condition === undefined || condition
        if (!showModal) { return new Promise((resolve) => { resolve(data) }) }
        setContent(content)
        setModalShow(true)
        setPropsOnShow(data)
        return new Promise((resolve) => { setResolver({ run: resolve }) })
    }

    return { show }
}

export const PopUpContainer = ({ children }) => {

    const [modalShow, setModalShow] = useState()
    const [content, setContent] = useState()
    const [resolver, setResolver] = useState()
    const [propsOnShow, setPropsOnShow] = useState({})
    const [page, setPage] = useState(0)

    const autoHide = content?.type.autoHide
    const hideCloseBtn = content?.type.hideCloseBtn
    const css = content?.type.css
    const width = content?.type.width
    const className = content?.type.className

    let pagesCount = useRef(0)

    useEffect(() => {
        modalShow && autoHide && autoHider(autoHide, modalShow, setModalShow)
    }, [autoHide, modalShow])

    function Handler(type, data) {
        resolver.run({ [type]: true, ...data })
        setModalShow(false)
        setPage(0)
    }

    function backdropClickHandler() {
        Handler('close')
        timeOutId && clearTimeout(timeOutId)
        timeOutId = null
    }

    function Pages({ children }) {
        let pages = React.Children.toArray(children)
        pagesCount.current = pages.length
        return pages[page]
    }

    function Next() {
        page !== pagesCount.current - 1 && setPage(s => s + 1)
    }

    function Back() {
        page !== 0 && setPage(s => s - 1)
    }

    function Navigate(page) {
        setPage(page)
    }

    return <PopupContext.Provider value={{ setModalShow, setContent, setResolver, setPropsOnShow }}>
        {children}
        <Modal show={modalShow} onBackdropClick={backdropClickHandler} >
            <PopupContent className={className} css={css} handler={Handler} hideCloseBtn={hideCloseBtn} width={width}>
                {
                    content && React.cloneElement(content, {
                        M: {
                            Handler,
                            Pages,
                            Next,
                            Back,
                            Navigate
                        },
                        ...propsOnShow
                    })
                }
            </PopupContent>
        </Modal>
    </PopupContext.Provider>
}

function autoHider(outTime, modalShow, setModalShow) {
    if (!timeOutId) {
        timeOutId = setTimeout(() => {
            if (modalShow) {
                setModalShow(false)
                clearTimeout(timeOutId)
                timeOutId = null
            }
        }, outTime === true ? 3000 : outTime)
    }
}