import React, { useEffect, useState } from 'react'
import './Button.scss'
import { Spinner } from 'react-bootstrap'
import useTranslate from '../../../_common/_Hooks/useTranslate'
import { classMaker } from '../..'

export default function Button({ className, css, content, icon, onClick, loader, reverse, width, style = {}, height, fontSize, disabled, iconWidth, condition, mb, mt, mr, ml, br }) {

  const { t } = useTranslate()

  if (!condition && condition !== undefined) return null

  return (
    <button
      id='Button'
      className={classMaker(className, css, 'Button') + (reverse ? ' reverse' : '')}
      style={{
        ...style,
        width: width,
        height: height,
        fontSize: fontSize,
        marginTop: mt,
        marginBottom: mb,
        marginRight: mr,
        marginLeft: ml,
        borderRadius: br
      }}
      onClick={() => { onClick && onClick() }}
      disabled={disabled}
    >
      {t(content)}
      {loader
        ? <Spinner animation="border" role="status" size="sm"></Spinner>
        : icon && <img className='btn_icon' src={icon} style={{ width: iconWidth }} alt="" />
      }
    </button>
  )
}
Button.displayName = 'Button'

export function Buttons({ className, css, children, end, start, center, mb, mt, mr, ml }) {
  return <div id='Buttons'
    className={classMaker(className, css, 'Button') + (start ? ' start' : center ? ' center' : end ? ' end' : '')}
    style={{ marginBottom: mb, marginTop: mt, marginRight: mr, marginLeft: ml }}
  >
    {children}
  </div>
}

{/* 

<Button
  className=''
  content={'button name'}
  icon={'icon path'}
  reverse
  iconWidth='15px'
  width='100px'
  height='50px'
  disabled={true || false}
  onClick={() => {}}
/> 

*/}