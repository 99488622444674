import React, { useState } from 'react'
import './TranslationManagement.scss'
import Button from '../../_components/Button/Button'
import FileUpload from '../../_components/FileUpload/FileUpload'
import Input from '../../_components/Input/Input'
import Div from '../../_components/Div/Div'
import icon_tick from './tran_tick.svg'
import icon_delete from './tran_delete.svg'
import icon_reset from './tran_reset.svg'
import useForm from '../../_Hooks/useForm'
import Flex from '../../_components/Flex/Flex'

export default function Tharjuma({ data, onSave, onClearUnverified, onDelete, onVerify, onImport }) {

    const [toggleOn, setToggle] = useState(false)

    const { formPlug, inputs, validate, clearInputs } = useForm()

    async function saveHandler() {
        if (!await validate()) return
        onSave && onSave(inputs)
        clearInputs()
    }

    return (
        <div id='TranslationManagement'>
            <Button css={1} content='Manage' onClick={() => setToggle(s => !s)} />
            {
                toggleOn && <>

                    <Div mt={10}>
                        <Input
                            css={1}
                            mb={10}
                            width={820}
                            label='Title'
                            field='title'
                            inputSocket={formPlug}
                            required
                        />
                        <br />
                        <Input
                            css={1}
                            mb={10}
                            ml={20}
                            width={400}
                            label='English'
                            field='english'
                            inputSocket={formPlug}
                            required
                        />
                        <Input
                            css={1}
                            mb={10}
                            ml={20}
                            width={400}
                            label='Arabic'
                            field='arabic'
                            inputSocket={formPlug}
                            required
                        />
                        <Flex mt={10} width={320} gap={20}>
                            <Button css={1} content='Save' onClick={saveHandler} />
                            <FileUpload css={4} field='import' label='' buttonLabel='Import' onChange={onImport && onImport} />
                        </Flex>
                    </Div>

                    <table>
                        <thead>
                            <tr>
                                <th width='80px'>Sl no</th>
                                <th width='300px'>Title</th>
                                <th width='300px'>English</th>
                                <th width='300px'>Arabic</th>
                                <th width='100px' className='verified'>
                                    <img src={icon_reset} width={20} onClick={() => onClearUnverified && onClearUnverified()} />
                                    Verified
                                </th>
                                <th width='100px' className='action'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{item?.title}</td>
                                            <td>{item?.english_text}</td>
                                            <td>{item?.arabic_text}</td>
                                            <td className='verified'>{item.is_verified
                                                ? <img src={icon_tick} width={16} />
                                                : !item.is_verified && <button onClick={() => { onVerify && onVerify(item) }}>Verify</button>
                                            }</td>
                                            <td className='action'>
                                                <img width={13} src={icon_delete} onClick={() => { onDelete && onDelete(item) }} />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </>
            }
        </div >
    )
}