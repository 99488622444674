import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateSystem } from '..'

export default function useFilter({ initialFilter, Fetch, Export, dependency = [], noInitialCall }) {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    let path = window.location.hash ? window.location.hash.replace("#", "") : window.location.pathname
    let key = path.split('/').join('')
    let routeDetails = useSelector(s => s.system.prevRoute)
    let preFilter = useSelector(s => s.system[key])
    let pageInside = useSelector(s => s.system.pageInside)

    const [filterData, setData] = useState({ ...initialFilter, ...(Export ? { export: true } : {}) })

    useEffect(() => {
        if (!noInitialCall) {
            if (routeDetails?.curr === path) {
                if (routeDetails && pageInside && routeDetails?.prev.includes(pageInside)) {
                    if (preFilter) {
                        setData({ ...preFilter })
                        Fetch && Fetch({ ...preFilter })
                    } else Fetch && Fetch(initialFilter)
                } else Fetch && Fetch(initialFilter)
            }
        }
        return () => {
            setData({ ...initialFilter, ...(Export ? { export: true } : {}) })
        }
    }, [preFilter, routeDetails, pageInside, ...dependency])

    function F_navigate(route, { state } = {}) {
        dispatch(updateSystem({ key: key, value: filterData }))
        dispatch(updateSystem({ key: 'pageInside', value: route }))
        navigate(route, { state: state || '' })
    }

    function filterHandler(key, value) {
        let filter;
        switch (key) {
            case 'filterBox': filter = { ...filterData, ...value, keyword: '', page: 0 }; break;
            case 'clearSearch': filter = { ...initialFilter, ...(Export ? { export: true } : {}) }; break;
            case 'keyword': filter = { ...filterData, keyword: value, page: 0 }; break;
            case 'sort': filter = { ...filterData, sort_key: value.key, sort_order: value.value, }; break;
            case 'page': filter = { ...filterData, page: value }; break;
            case 'entries': filter = { ...filterData, page: 0, perPage: parseInt(value) }; break;
            case 'custom': filter = { ...filterData, ...value, page: 0 }
            default:
        }
        if (!filter) return
        setData(filter)
        Fetch(filter)
    }

    function customFilter(e, field) {
        filterHandler('custom', { [field]: e })
    }

    filterHandler.export = () => {
        Export && Export(filterData)
    }

    function setFilterData(obj) {
        let res = { ...filterData, ...obj }
        setData(res)
        return res
    }

    return { filterData, setFilterData, filterHandler, F_navigate, initialFilter, customFilter }
}