import React from 'react'
import './Samples.scss'

export default function Samples({ content = {} }) {

    return (
        <div id='Samples'>
            <h1>Style Samples</h1>
            <hr />
            <table>
                {
                    Object.keys(content).map((item, i) => {
                        if (Object.keys(content[item][0] || {}).length === 0) return null
                        return (
                            <React.Fragment key={i}>
                                <thead>
                                    <tr>
                                        <th colSpan={2}><h3>{item}</h3></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {content[item].map((ele, j) => {
                                        return (
                                            <tr key={j}>
                                                <td>{ele.props?.css || 0}</td>
                                                <td>{ele}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </React.Fragment>
                        )
                    })
                }
            </table>
        </div>
    )
}