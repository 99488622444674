import React, { Children } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import useTranslate from '../../_Hooks/useTranslate'

export default function Hint({ children, hint }) {

    const { t } = useTranslate()

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props} style={{ fontSize: '12px' }}>
            {props.label}
        </Tooltip>
    )

    return (
        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={renderTooltip({ label: t(hint) })} >
            {children}
        </OverlayTrigger>
    )
}