import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

export default function ProtectedRouter() {

    const location = useLocation()

    const token = localStorage.getItem('token')

    return location.pathname === '/login'
        ? token
            ? <Navigate to='/' />
            : <Outlet />
        : token
            ? <Outlet />
            : <Navigate to='/login' />
}