import React from 'react'
import './Dash_card.scss'
import { useNavigate } from 'react-router-dom'

export default function Dash_card({ icon, status, value, previous, color, data }) {

    const navigate = useNavigate()

    return (
        <div id="Dash_card" style={{ borderColor: color }} onClick={() => { data?._id && navigate('status/' + data?._id) }}>
            <div className="header">
                <div className="icon"><img src={icon} alt="" /></div>
                <div className='title'>
                    {status}
                    <p>{data?.count}</p>
                </div>
            </div>
            <div className="prevWeek">
                <p>previous : {data?.compareCount}</p>
            </div>
        </div>
    )
}
