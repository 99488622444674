import React from 'react'
import Page from '../../_common/_components/Page/Page'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import useTranslate from '../../_common/_Hooks/useTranslate'
import useFilter from '../../_common/_Hooks/useFilter'
import Select from '../../_common/_components/Select/Select'
import Table from '../../_common/_components/Table/Table'
import Button from '../../_common/_components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { Modals } from '../../_common/_components/ModalsContainer/ModalsContainer'
import useFetch from '../../_common/_Hooks/useFetch'
import Actions from '../../_common/_components/Actions/Actions'
import StatusBox from '../../_common/_components/StatusBox/StatusBox'
import { IMG_BASE_URL } from '../../config'
import LabelValue from '../../_common/_components/LabelValue/LabelValue'
import { useSelector } from 'react-redux'
import useModal from '../../_common/_Hooks/useModal'
import { AddEditVolunteer, Success } from '../../common/Modals'

export default function Volunteers() {

    const { _lang } = useTranslate()
    const navigate = useNavigate()
    const statuses = useSelector(s => s.statuses)
    const m_AddEdit = useModal(<AddEditVolunteer />)

    // *********************************************************************************************************************
    const { req: addVolunteer } = useFetch({
        endpoint: 'volunteer/addVolunteer',
        method: 'post',
        type: 'raw',
        toast: true,
    })
    // *********************************************************************************************************************
    const { req: getVolunteers, res: volunteers_res } = useFetch({
        endpoint: 'volunteer/getVolunteer',
        method: 'post',
        type: 'raw',
    })
    // *********************************************************************************************************************
    const { req: exportVolunteers } = useFetch({
        endpoint: 'volunteer/getVolunteerExport',
        method: 'post',
        type: 'raw',
        download: true
    })
    // *********************************************************************************************************************
    const { req: deleteVolunteer } = useFetch({
        endpoint: 'volunteer/deleteVolunteers',
        method: 'delete',
        type: 'params',
        toast: true
    })
    // *********************************************************************************************************************
    const { req: uploadFiles, lod: fileUploadLoader } = useFetch({
        endpoint: 'file/uploadFile',
        method: 'post',
        type: 'formdata',
        privateLoader: true
    })
    // *********************************************************************************************************************
    const volunteers = volunteers_res?.data
    const pageDetails = volunteers_res?.page
    // *********************************************************************************************************************

    const { filterData, filterHandler, F_navigate, customFilter } = useFilter({
        initialFilter: { page: 0, perPage: 10 },
        Fetch: getVolunteers,
    });

    async function addVolunteerHandler(data = {}) {
        const obj = {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone_number: data.phone_number,
            customer_contact_code: data.customer_contact_code || {
                "_id": "667e5754a017a0408a9f1f5b",
                "country": "United Arab Emirates",
                "handle_key": "united_arab_emirates",
                "code": "+971",
                "flag": "united_arab_emirates.svg"
            },
            ...filer('eid', { base: IMG_BASE_URL, folder: volunteers_res?.path, ends: data.eid }),
            ...filer('attachments', { base: IMG_BASE_URL, folder: volunteers_res?.path, ends: data.attachments }),
        }
        const { cancel, close, m_inputs } = await m_AddEdit.show({ isEdit: data._id, m_inputs: obj, fileUploader: uploadFiles })
        if (cancel || close) return
        addVolunteer({
            id: data._id,
            first_name: m_inputs.first_name,
            last_name: m_inputs.last_name,
            email: m_inputs.email,
            phone_number: m_inputs.phone_number,
            customer_contact_code: m_inputs.customer_contact_code.code,
            eid: m_inputs.eid_filenames,
            attachments: m_inputs.attachments_filenames,
            track_id: m_inputs.track_id,
            request_type: "admin",
            status: data.status?._id
        }, (res) => {
            getVolunteers(filterData)
        })
    }

    function exportHandler() {
        exportVolunteers(filterData)
    }

    async function deleteHandler(id) {
        const { cancel } = await Modals.Confirm({ action: 'Delete' })
        if (cancel) return
        deleteVolunteer(id, () => {
            getVolunteers(filterData)
        })
    }

    return (
        <Page>

            <Page.Header>
                <Title title={'Volunteers'} />
                <Breadcrumb />
                <Button content='Add Volunteer' css={1.1} icon={'/assets/icons/plus.svg'} reverse onClick={addVolunteerHandler} />
            </Page.Header>

            <Page.Body >
                <Table
                    css={1.2}
                    className='volunteers_table'
                    _css={{ search: 2, pagination: 1, entries: 1 }}
                    moreFilters={
                        <>
                            <Select css={1.1} field='status' placeholder='Select Status' width={300} showCloseBtn onChange={(e, key) => customFilter(e._id, key)} value={filterData.status}
                                content={{ op: Object.values(statuses).filter(a => ['approved', 'rejected', 'to_be_reviewed'].includes(a.handle_key)), lb: 'request_status', vl: '_id' }}
                            />
                            <Button content='Export List' css={1.1} icon={'/assets/icons/upload1.svg'} onClick={exportHandler} />
                        </>
                    }
                    titles={[
                        { title: 'First Name', key: 'first_name', sort: true },
                        { title: 'Last Name', key: 'last_name', sort: true },
                        { title: 'Phone Number', key: 'phone_number', sort: true, dir: 'ltr', d_align: 'right' },
                        { title: 'Email', key: 'email', sort: true },
                        { title: 'Emirates ID', key: 'eid' },
                        { title: 'Approval Status', key: 'status', sort: true },
                        { title: 'Action', key: 'action' }
                    ]}
                    content={volunteers?.map((r) => {
                        return {
                            _id: r._id,
                            first_name: r.first_name,
                            last_name: r.last_name,
                            phone_number: r?.phone_number ? ((r?.customer_contact_code || '+971') + ' ' + r?.phone_number) : '-',
                            email: r.email,
                            eid: r.eid.length > 0 && <LabelValue valueIcon='/assets/icons/id.svg' iconOnly url={`${IMG_BASE_URL}volunteer/${r.eid}`} iconHeight={35} />,
                            status: <StatusBox css={1} status={r.status?.request_status} />,
                            action: <Actions buttons={{ view: '/assets/icons/eye.svg', edit: '/assets/icons/edit.svg', delete: '/assets/icons/delete.svg' }}
                                visibility={{ edit: r.status?.handle_key !== 'rejected' }}
                                onView={() => { F_navigate('volunteer_detail/' + r._id) }}
                                onEdit={() => addVolunteerHandler(r)}
                                onDelete={() => deleteHandler(r._id)}
                            />,
                        }
                    })}
                    onRowClick={(e) => { F_navigate('volunteer_detail/' + e._id) }}
                    pageDetails={pageDetails}
                    filterHandler={filterHandler}
                    filterData={filterData}
                />
            </Page.Body>

        </Page>
    )
}

function filer(field, { base, folder, ends }) {
    return {
        [field]: ends?.map(item => {
            return {
                url: `${base}${folder}/${item}`,
                name: item,
                filename: item,
                type: item.split('.').pop().toLowerCase()
            }
        }),
        [field + '_filenames']: ends
    }
}