import React from 'react'
import Page from '../../_common/_components/Page/Page'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import Button from '../../_common/_components/Button/Button'
import Table from '../../_common/_components/Table/Table'
import Actions from '../../_common/_components/Actions/Actions'
import { useNavigate } from 'react-router'
import useFetch from '../../_common/_Hooks/useFetch'
import useTranslate from '../../_common/_Hooks/useTranslate'
import useFilter from '../../_common/_Hooks/useFilter'
import DateViewer from '../../_common/_components/DateViewer/DateViewer'
import { Modals } from '../../_common/_components/ModalsContainer/ModalsContainer'
import { useSelector } from 'react-redux'
import StatusBox from '../../_common/_components/StatusBox/StatusBox'

export default function Instagram() {

    const navigate = useNavigate()
    const { _lang } = useTranslate()

    const user = useSelector(s => s.user)
    const forApproval = user?.approve_instagram_feeds

    // *********************************************************************************************************************
    const { req: getFeeds, res: feeds_res } = useFetch({
        endpoint: 'instagram/listInstagramFeeds',
        method: 'post',
        type: 'raw',
    })
    // *********************************************************************************************************************
    const { req: deleteFeed } = useFetch({
        endpoint: 'instagram/deleteInstagramPosts',
        method: 'put',
        type: 'params',
        toast: true,
    })
    // *********************************************************************************************************************
    const feeds = feeds_res?.data
    const pageDetails = feeds_res?.page
    const canAct = user?.upload_instagram_feeds
    const canView = user?.view_instagram_feeds
    // *********************************************************************************************************************

    const { filterData, filterHandler, F_navigate } = useFilter({
        initialFilter: { page: 0, perPage: 10 },
        Fetch: getFeeds
    })

    async function deleteHandler(id) {
        let { cancel } = await Modals.Confirm({ action: 'Delete' })
        if (cancel) return
        deleteFeed(id, () => {
            getFeeds(filterData)
        })
    }

    return (
        <Page>
            <Page.Header>
                <Title title='Instagram' />
                <Breadcrumb />
                {user?.upload_instagram_feeds && <Button content='Add Feed' css={1.1} icon={'/assets/icons/plus.svg'} reverse onClick={() => { navigate('add_feed') }} />}
            </Page.Header>

            <Page.Body>
                <Table
                    css={1}
                    _css={{ search: 2, pagination: 1, entries: 1 }}
                    titles={[
                        { title: 'Created Date', key: 'created_date', sort: true },
                        { title: 'Created By', key: 'created_by', sort: true },
                        { title: 'Status', key: 'status', sort: true },
                        { title: 'To Be Posted On', key: 'posting_date', sort: true },
                        { title: 'Action', key: 'action' },
                    ]}
                    content={
                        feeds?.map(((a, i) => {
                            return {
                                _id: a._id,
                                created_date: <DateViewer dateTime={a.createdAt} />,
                                created_by: a.created_by?.['first_name' + _lang],
                                status: <StatusBox css={1} status={a.status?.request_status} />,
                                posting_date: <DateViewer dateTime={a.toBePosted} dateOnly />,
                                action: <Actions buttons={{ view: '/assets/icons/view.svg', delete: '/assets/icons/delete.svg', edit: '/assets/icons/edit.svg' }}
                                    visibility={{
                                        view: canAct || canView || forApproval,
                                        edit: (canAct || forApproval) && a.can_act,
                                        delete: user?.role === 'super_admin' || user?._id === a.created_by?._id
                                    }}
                                    onView={() => { F_navigate('/social_media/instagram/view_feed/' + a._id) }}
                                    onEdit={() => { F_navigate('/social_media/instagram/edit_feed/' + a._id) }}
                                    onDelete={() => { deleteHandler(a._id) }}
                                />,
                            }
                        }))}
                    onRowClick={(e) => { F_navigate('/social_media/instagram/view_feed/' + e._id) }}
                    pageDetails={pageDetails}
                    filterData={filterData}
                    filterHandler={filterHandler}
                />
            </Page.Body>
        </Page>
    )
}
