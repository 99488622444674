export const API_BASE_URL = "https://al-ameen-api.shtdevops.xyz/"
export const IMG_BASE_URL = "https://al-ameen-api.shtdevops.xyz/"
export const CALL_BASE_URL = "https://dev002.bitvoice.ae/makecall/"

// export const API_BASE_URL = "http://192.168.1.193:8041/"
// export const IMG_BASE_URL = "http://192.168.1.193:8041/"

// export const API_BASE_URL = "https://localhost:8041/"
// export const IMG_BASE_URL = "https://localhost:8041/"

// export const API_BASE_URL = "http://24.199.89.100/crm-backend/"
// export const IMG_BASE_URL = "http://24.199.89.100/crm-backend/"
// export const API_BASE_URL = `https://${window.location.origin}/crm-backend/`
// export const IMG_BASE_URL = `https://${window.location.origin}/crm-backend/mnt/data/crm/uploads/`
// export const CALL_BASE_URL = `http://172.18.54.41/makecall/`