import React from 'react'
import Page from '../../_common/_components/Page/Page'
import TitleBar from '../../_common/_components/TitleBar/TitleBar'
import LabelValue from '../../_common/_components/LabelValue/LabelValue'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import Title from '../../_common/_components/Title/Title'
import Button from '../../_common/_components/Button/Button'
import { useNavigate } from 'react-router-dom'
import Div from '../../_common/_components/Div/Div'
import useFetch from '../../_common/_Hooks/useFetch'
import useFilter from '../../_common/_Hooks/useFilter'
import FilterBox from '../../_common/_components/FilterBox/FilterBox'
import useTranslate from '../../_common/_Hooks/useTranslate'
import DatePick from '../../_common/_components/DatePick/DatePick'
import Select from '../../_common/_components/Select/Select'
import DateViewer from '../../_common/_components/DateViewer/DateViewer'
import StatusBox from '../../_common/_components/StatusBox/StatusBox'
import Actions from '../../_common/_components/Actions/Actions'
import Table from '../../_common/_components/Table/Table'

export default function LR_Requests() {

    const navigate = useNavigate()
    const { _lang } = useTranslate()

    // *********************************************************************************************************************
    const { req: getRequests, res: requests_res } = useFetch({
        endpoint: 'lecture-request/getRequest',
        method: 'post',
        type: 'raw',
    })
    // *********************************************************************************************************************
    const { req: getFilters, res: filters_res } = useFetch({
        endpoint: 'common/getFilterListInLectureRequest',
        method: 'get',
        type: 'raw',
        selfCall: true,
    })
    // *********************************************************************************************************************
    const { res: statuses_res } = useFetch({
        endpoint: 'common/getAllStatus',
        method: 'get',
        type: 'raw',
        toast: false,
        selfCall: true,
        privateLoader: true
    })
    // *********************************************************************************************************************
    const statuses_for_filter = ["rejected", "cancelled", "to_be_reviewed", "confirm", "not_responded", "completed"]
    const statuses = statuses_res?.data.filter(s => statuses_for_filter.includes(s.handle_key))
    const requests = requests_res?.data
    const filters = filters_res?.data
    const pageDetails = requests_res?.page
    // *********************************************************************************************************************

    const { filterData, filterHandler, F_navigate } = useFilter({
        initialFilter: { page: 0, perPage: 10 },
        Fetch: (filter) => {
            getRequests({
                ...filter,
                // agentId: filter?.agent_name?._id,
                // department_id: filter?.department?._id,
                // incidentType: filter?.incident_type?._id,
                // status: filter?.status?._id,
                // request_type: filter?.request_type?.value
            })
        },
    });

    return (
        <Page className='lr_requests'>
            <Page.Header>
                <Title title={'Requests'} />
                <Breadcrumb />
            </Page.Header>
            <Page.Body css={1}>
                <FilterBox
                    _css={{ Select: 1, DatePick: 1, Input: 1, Search_Btn: 1.1, Clear_Btn: 1.2 }}
                    _placeholder={{ Select: 'Select', Input: 'Type in input' }}
                    showCloseBtns
                    filterHandler={filterHandler}
                    filterData={filterData}
                >
                    <DatePick label='Lecture From' timeSelect field='requestFrom' maxDate={(e) => e.requestTo} />
                    <DatePick label='Lecture To' timeSelect field='requestTo' minDate={(e) => e.requestFrom} />
                    <Select label='Coordinator Name' field='coordinator_name' content={{ op: filters?.filter(a => a.coordinator_name), lb: 'coordinator_name', vl: 'coordinator_name' }} />
                    <Select label='Beneficiary' field='beneficiary' content={{ op: filters?.filter(a => a.beneficiary), lb: 'beneficiary', vl: 'beneficiary' }} />
                    <Select label='Status' field='status' content={{ op: statuses, lb: 'request_status', vl: '_id' }} />
                </FilterBox>
                <Table
                    css={1}
                    _css={{ search: 2, pagination: 1, entries: 1 }}
                    titles={[
                        { title: 'Request Number', key: 'request_no', sort: true },
                        { title: 'Request Date', key: 'request_date', sort: true },
                        { title: 'Coordinator Name', key: 'coordinator_name', sort: true },
                        { title: 'Contact Number', key: 'contact_number', sort: true, dir: 'ltr', d_align: 'right' },
                        { title: 'Lecture From', key: 'request_from', sort: true },
                        { title: 'Lecture To', key: 'request_to', sort: true },
                        { title: 'Beneficiary', key: 'beneficiary', sort: true },
                        { title: 'Status', key: 'status', sort: true },
                        // { title: 'Action', key: 'action' },
                    ]}
                    content={requests?.map((request) => {
                        return {
                            id: request._id,
                            request_no: request.request_no,
                            request_date: <DateViewer dateTime={request.created_date} dateOnly />,
                            // coordinator_name: <Image src={request.created_by[0]?.profile_image ? `${IMG_BASE_URL}profile/${request.created_by[0]?.profile_image}` : '/assets/img/user.jpg'} label={request.created_by[0]?.['first_name' + _lang]} />,
                            coordinator_name: request.coordinator_name,
                            // contact_number: request.contact_number,
                            contact_number: request?.contact_number ? ((request?.customer_contact_code || '+971') + ' ' + request?.contact_number) : '-',
                            request_from: <DateViewer dateTime={request.request_from} />,
                            request_to: <DateViewer dateTime={request.request_to} />,
                            beneficiary: request?.beneficiary,
                            status: <StatusBox css={1} status={request.status?.request_status} />,
                            // action: <Actions
                            //     buttons={{ view: '/assets/icons/eye.svg' }}
                            //     onView={() => F_navigate(`/lecuture_request/requests/view_request/${request._id}`)} />
                        }
                    })}
                    onRowClick={(e) => { F_navigate(`/lecuture_request/requests/view_request/${e.id}`) }}
                    pageDetails={pageDetails}
                    filterHandler={filterHandler}
                    filterData={filterData}
                />
            </Page.Body>
        </Page>
    )
}