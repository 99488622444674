import React from 'react'
import './Notifications.scss'
import { classMaker } from '../..'
import icon_bell from './not_bell.svg'
import ToolTip from '../ToolTip/ToolTip'
import useTranslate from '../../_Hooks/useTranslate'
import Button, { Buttons } from '../Button/Button'

export default function Notifications({ className, css, content = [], count = 0, children, onClear, onClearAll, hasMore, onLoadMore }) {

    const { t } = useTranslate()

    return (
        <ToolTip anchor={
            <span>
                <img className='notification_icon' src={icon_bell} alt="" />
                {content?.length > 0 && <span className='new_notifications'></span>}
            </span>
        } >
            {
                ({ popupClose }) => (
                    <div id='Notifications' className={classMaker(className, css, 'Notifications')}>
                        <div className='header'>
                            <p className='title'>{t("Notifications")}</p>
                            <p className='count'>{count > 99 ? '+99' : count}</p>
                        </div>
                        <ul>
                            {
                                content?.map((item, i) => {
                                    return (
                                        <li key={i}>
                                            {
                                                children(item, { popupClose })
                                            }
                                            <button onClick={() => { onClear && onClear(item) }}>✖</button>
                                        </li>
                                    )
                                })
                            }
                            {
                                <Buttons center mb={10} mt={10}>
                                    <Button css={1} content='Load More' onClick={() => onLoadMore && onLoadMore()} />
                                </Buttons>
                            }
                        </ul>
                        <div className='footer'>
                            {content?.length > 0 && <button onClick={() => { onClearAll && onClearAll() }}>{t("Clear all")}</button>}
                        </div>
                    </div>
                )
            }
        </ToolTip>
    )
}
