import React, { useEffect, useRef, useState } from 'react'
import './Flex.scss'
import { classMaker } from '../..'

export default function Flex({ className, css, children, onEmpty, gap = 0, gc, gr, _minWidth = 150, width, columns = 1, mb, mt, mr, ml }) {

  const ref = useRef()

  useEffect(() => {
    if (onEmpty) {
      let c = Array.isArray(children) ? children : [children]
      let hasChildren = c.some(s => s)
      !hasChildren && onEmpty()
    }
  }, [])

  children = React.Children.toArray(children).filter(child => child)

  return (
    <div id='Flex' className={classMaker(className, css, 'Flex')} ref={ref} style={{
      '--fx_g': typeof gap === 'number' ? gap + 'px' : gap,
      '--fx_b': columns > 1 ? Math.floor(100 / columns) + '%' : 'auto',
      '--fx_cmw': typeof _minWidth === 'number' ? _minWidth + 'px' : _minWidth,
      '--fx_c': columns,
      width: width,
      columnGap: gc,
      rowGap: gr,
      marginTop: mt,
      marginBottom: mb,
      marginRight: mr,
      marginLeft: ml
    }} >
      {children}
      {
        (children.length === columns
          ? Array(children.length - 1)
          : Array((Math.ceil(children.length / columns) * columns) - children.length + 1)
        ).fill().map((a, i) => {
          return <div key={i} className='fx_x'></div>
        })
      }
    </div >
  )
}