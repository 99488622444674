import React from "react";
import Title from "../../_common/_components/Title/Title";
import Breadcrumb from "../../_common/_components/Breadcrumb/Breadcrumb";
import Button from "../../_common/_components/Button/Button";
import Table from "../../_common/_components/Table/Table";
import useFilter from "../../_common/_Hooks/useFilter";
import useFetch from "../../_common/_Hooks/useFetch";
import Actions from "../../_common/_components/Actions/Actions";
import Page from "../../_common/_components/Page/Page";
import { Modals } from "../../_common/_components/ModalsContainer/ModalsContainer";

export default function Departments() {

  // *********************************************************************************************************************
  const { req: getDepartments, res: departments_res } = useFetch({
    endpoint: "department/getDepartment",
    method: "post",
    type: "formdata",
  })
  // *********************************************************************************************************************
  const { req: addDepartment } = useFetch({
    endpoint: "department/addDepartment",
    method: "post",
    type: "raw",
    toast: true
  })
  // *********************************************************************************************************************
  const { req: deleteDepartment } = useFetch({
    endpoint: "department/deleteDepartment",
    method: "delete",
    type: "params",
    toast: true
  })
  // *********************************************************************************************************************
  const { req: editDepartment } = useFetch({
    endpoint: "department/editDepartment",
    method: "put",
    type: "raw",
    toast: true
  })
  // *********************************************************************************************************************
  let departments = departments_res?.data
  let pageDetails = departments_res?.page
  // *********************************************************************************************************************

  const { filterData, filterHandler, F_navigate, initialFilter } = useFilter({
    initialFilter: { page: 0, perPage: 10 },
    Fetch: getDepartments
  })

  async function addHandler() {
    let { cancel, m_inputs } = await Modals.Add_Edit_department()
    if (cancel) return
    let obj = {
      department_name_en: m_inputs.department_name_en,
      department_name_ar: m_inputs.department_name_ar,
    }
    addDepartment(obj, () => {
      getDepartments(initialFilter)
    })
  }

  async function editHandler(data) {
    let { cancel, m_inputs } = await Modals.Add_Edit_department({ m_inputs: data, isEdit: true })
    if (cancel) return
    let obj = {
      id: data._id,
      department_name_en: m_inputs.department_name_en,
      department_name_ar: m_inputs.department_name_ar,
    }
    editDepartment(obj, () => {
      getDepartments(initialFilter)
    })
  }

  async function deleteHandler(id) {
    let { cancel } = await Modals.Confirm({ action: 'Delete' })
    if (cancel) return
    deleteDepartment(id, () => { getDepartments(initialFilter) })
  }

  return (
    <Page>

      <Page.Header>
        <Title title="Departments" />
        <Breadcrumb />
        <Button content='Add Department' css={1.1} icon={'/assets/icons/plus.svg'} reverse onClick={addHandler} width={190} />
      </Page.Header>

      <Page.Body>
        <Table
          css={1}
          _css={{ search: 2, pagination: 1, entries: 1 }}
          titles={[
            { title: 'Sl No', key: 'sl_no' },
            { title: 'Department Name[En]', key: 'department_en', sort: true },
            { title: 'Department Name[Ar]', key: 'department_ar', sort: true },
            { title: 'Action', key: 'action' },
          ]}
          content={
            departments?.map((d, i) => ({
              sl_no: i + 1,
              department_en: d.department_name_en,
              department_ar: d.department_name_ar,
              action: <Actions buttons={{ edit: '/assets/icons/edit.svg', delete: '/assets/icons/delete.svg' }}
                visibility={{ delete: !['escalate', 'customer_service'].includes(d.handle_key) }}
                onEdit={() => editHandler(d)}
                onDelete={() => deleteHandler(d._id)}
              />,
            }))}
          filterData={filterData}
          filterHandler={filterHandler}
          pageDetails={pageDetails}
        />
      </Page.Body>

    </Page>
  );
}