import React from 'react'
import Page from '../../_common/_components/Page/Page'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import FilterBox from '../../_common/_components/FilterBox/FilterBox'
import DatePick from '../../_common/_components/DatePick/DatePick'
import Select from '../../_common/_components/Select/Select'
import ReportCounts from '../../components/ReportCounts/ReportCounts'
import Table from '../../_common/_components/Table/Table'
import DateViewer from '../../_common/_components/DateViewer/DateViewer'
import StatusBox from '../../_common/_components/StatusBox/StatusBox'
import Actions from '../../_common/_components/Actions/Actions'
import useFilter from '../../_common/_Hooks/useFilter'
import useFetch from '../../_common/_Hooks/useFetch'
import useTranslate from '../../_common/_Hooks/useTranslate'
import Image from '../../_common/_components/Image/Image'

export default function LR_reports() {

    const { _lang } = useTranslate()

    // *********************************************************************************************************************
    const { req: getReports, res: report_res } = useFetch({
        endpoint: 'lecture-request/getRequestReport',
        method: 'post',
        type: 'formdata',
        toast: false,
    })
    // *********************************************************************************************************************
    const { req: exportReports } = useFetch({
        endpoint: 'lecture-request/getRequestReportExport',
        method: 'post',
        type: 'formdata',
        download: true
    })
    // *********************************************************************************************************************
    const { res: filters_res } = useFetch({
        endpoint: 'common/getFilterListInLectureRequest',
        method: 'get',
        type: 'raw',
        selfCall: true,
    })
    // *********************************************************************************************************************
    const { res: statuses_res } = useFetch({
        endpoint: 'common/getAllStatus',
        method: 'get',
        type: 'raw',
        toast: false,
        selfCall: true,
        privateLoader: true
    })
    // *********************************************************************************************************************
    const statuses_for_filter = ["rejected", "cancelled", "to_be_reviewed", "confirm", "not_responded", "completed"]
    const statuses = statuses_res?.data.filter(s => statuses_for_filter.includes(s.handle_key))
    const pageDetails = report_res?.page
    const countData = report_res?.countData
    const requests = report_res?.data
    const data = {
        'Cancelled': countData?.cancelled,
        'Completed': countData?.completed,
        'Confirmed': countData?.confirmed,
        'Not Responded': countData?.notResponded,
        'Rejected': countData?.rejected,
        'To Be Reviewed': countData?.toBeReviewed,
        'Total': countData?.totalCount
    }
    const filters = filters_res?.data
    // *********************************************************************************************************************

    const { filterData, filterHandler, F_navigate } = useFilter({
        initialFilter: { page: 0, perPage: 10 },
        Fetch: getReports,
        Export: exportReports
    })

    return (
        <Page>

            <Page.Header>
                <Title title='Reports' />
                <Breadcrumb />
            </Page.Header>

            <Page.Body css={1} >
                <FilterBox
                    _css={{ Select: 1, DatePick: 1, Input: 1, Search_Btn: 1.1, Clear_Btn: 1.2, Export_Btn: 3 }}
                    _placeholder={{ Select: 'Select', Input: 'Type in input' }}
                    showCloseBtns
                    filterHandler={filterHandler}
                    filterData={filterData}
                >
                    <DatePick label='Start Date' field='request_from' maxDate={(e) => e.request_to} />
                    <DatePick label='End Date' field='request_to' minDate={(e) => e.request_from} />
                    <Select label='Coordinator Name' field='coordinator_name' content={{ op: filters?.filter(a => a.coordinator_name), lb: 'coordinator_name', vl: 'coordinator_name' }} />
                    <Select label='Beneficiary' field='beneficiary' content={{ op: filters?.filter(a => a.beneficiary), lb: 'beneficiary', vl: 'beneficiary' }} />
                    <Select label='Status' field='status' content={{ op: statuses, lb: 'request_status', vl: '_id' }} />
                </FilterBox>

                <ReportCounts data={data} />

                <Table
                    css={1}
                    _css={{ search: 2, pagination: 1, entries: 1 }}
                    titles={[
                        { title: 'Request Number', key: 'request_no', sort: true },
                        { title: 'Request Date', key: 'request_date', sort: true },
                        { title: 'Coordinator Name', key: 'coordinator_name', sort: true },
                        { title: 'Contact Number', key: 'contact_number', sort: true },
                        { title: 'Lecture From', key: 'request_from', sort: true },
                        { title: 'Lecture To', key: 'request_to', sort: true },
                        { title: 'Beneficiary', key: 'beneficiary', sort: true },
                        { title: 'Status', key: 'status', sort: true },
                        // { title: 'Action', key: 'action' },
                    ]}
                    content={requests?.map(item => {
                        return {
                            id: item._id,
                            request_no: item.request_no,
                            request_date: <DateViewer dateTime={item.created_date} dateOnly />,
                            // coordinator_name: <Image src={item.created_by[0]?.profile_image ? `${IMG_BASE_URL}profile/${item.created_by[0]?.profile_image}` : '/assets/img/user.jpg'} label={item.created_by[0]?.['first_name' + _lang]} />,
                            coordinator_name: item.coordinator_name,
                            contact_number: item.contact_number,
                            request_from: <DateViewer dateTime={item.request_from} dateOnly />,
                            request_to: <DateViewer dateTime={item.request_to} dateOnly />,
                            beneficiary: item.beneficiary,
                            status: <StatusBox css={1} status={item.status?.request_status} />,
                            // action: <Actions
                            //     buttons={{ view: '/assets/icons/eye.svg' }}
                            //     onView={() => F_navigate(`/lecuture_request/requests/view_request/${item._id}`)} />
                        }
                    })}
                    onRowClick={(e) => { F_navigate(`/lecuture_request/requests/view_request/${e.id}`) }}
                    pageDetails={pageDetails}
                    filterHandler={filterHandler}
                    filterData={filterData}
                />
            </Page.Body>
        </Page>
    )
}