import React from 'react'
import './TitleBar.scss'
import { useState } from 'react'
import { useEffect } from 'react'
import ArrowDownIcon from "./tlb_toggle_arrow.svg"
import useTranslate from '../../_Hooks/useTranslate'
import { classMaker } from '../..'
import config from '../../../common/config'

export default function TitleBar({ className, css, title, toggleButton, defaultToggle = 'show', top, boxClassName = '', children, titleEndElement, titleMore, toggleIcons = { hide: '', show: '', size: '' }, hideOnEmpty }) {

  const { t } = useTranslate()

  const [inTitle, setTitle] = useState('')
  const [expandToggle, setExpandToggle] = useState(false)

  useEffect(() => {
    title && setTitle(title)
    defaultToggle === 'show' && setExpandToggle(true)
    return () => {
      setTitle('')
      setExpandToggle(false)
    }
  }, [title, defaultToggle])

  if (hideOnEmpty && [...(Array.isArray(children) ? [...children] : [children])].every(item => item === false))
    return null

  return (
    <div id='TitleBar' className={classMaker(className, css, 'TitleBar')}>
      <div className={'header' + (top ? ' top' : '')} onClick={() => setExpandToggle(s => !s)}>
        <h3>{t(inTitle)}{titleMore}</h3>
        <div className='actions'>
          {titleEndElement}
          {toggleButton && (
            Object.values(toggleIcons).join('').length > 0
              ? <img src={expandToggle ? toggleIcons.show : toggleIcons.hide} alt="" style={{ width: toggleIcons.size }}
                onClick={() => setExpandToggle(s => !s)} className='toggleIcon' />
              : <img className={'toggleIcon' + (expandToggle ? ' tb_expand' : ' tb_collapse')}
                src={config.Titlebar_arrow || ArrowDownIcon} alt="" />
          )}
        </div>
      </div>
      {children &&
        <section className={'tb_content ' + boxClassName + ((expandToggle || !toggleButton) ? ' tb_show' : ' tb_hide')}>
          {children}
        </section >
      }
    </div>
  )
}


{/*

<TitleBar
  title={'History'}
  toggleButton
  defaultToggle={'hide' || 'show'}
>
</TitleBar>

*/}