import { API_BASE_URL, IMG_BASE_URL } from "../config";
import AppLoader from "../components/AppLoader";
import NetworkLost from "../components/NetworkLost";
import PageNotFound from "../components/PageNotFound";
import routes from "../routes";

export default {
    __api_base_url: API_BASE_URL,
    __dir: 'rtl',
    __routes: routes,
    _Inactivity_timeOut: 60000 * 5,
    // _loader: <AppLoader />,
    _tokenRefreshInterval: 180000,
    _network_lost: <NetworkLost />,
    _page_not_found: <PageNotFound />,
    FilterBox_export_icon: '/assets/icons/export.svg',
    Image_alt: '/assets/img/user.jpg',
    Input_placeholder: 'Type here',
    Select_placeholder: 'Select',
    Table_entries_arrow_up: null,
    Table_entries_down_up: null,
    Titlebar_arrow: '/assets/icons/arrow_red_down.svg',
    useForm_imager_api_base_url: IMG_BASE_URL,
    useForm_imager_folder: 'profile',
    useTranslate_autoTranslate: {
        autoTranslate: false,
        except: ['✖', 'en', 'ar', 'english', 'import excel']
    }
}