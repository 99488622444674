import React, { useEffect, useState } from 'react'
import './TextArea.scss'
import { v4 } from 'uuid'
import { classMaker } from '../..'
import useTranslate from '../../_Hooks/useTranslate'

let validationObj = {
  minChar: { message: '', condition: '', length: '' },
  maxChar: { message: '', condition: '', length: '' },
  alphabetsOnly: { message: '', condition: '', more: '' },
  alphaNumeric: { message: '', condition: '', more: '' },
}

export default function TextArea({ className, css, label, value, onChange, field, placeholder, inputSocket, error, required, validation = validationObj, condition, clearOnHide, hideStar, mb, mt, mr, ml }) {

  let connector = inputSocket?.connector
  error = error || inputSocket?.errors
  value = value || inputSocket?.inputs
  onChange = onChange || inputSocket?.inputHandler

  let uuid = v4()
  const { t, lang } = useTranslate()

  const [inValue, setInValue] = useState('')
  const [inLabel, setInLabel] = useState('')
  const [inPlaceholder, setInPlaceholder] = useState('')
  const [inError, setInError] = useState('')

  useEffect(() => {
    setInValue(() => {
      let res = ''
      if (typeof value === 'object' && value?.[field] !== undefined) res = value?.[field]
      else if (typeof value === 'object' && value?.[field] === undefined) res = ''
      else if (value !== undefined) res = value
      return res
    })
    if (!field) setInError('Please add field')
    placeholder && setInPlaceholder(placeholder)
    label && setInLabel(label)
    return () => {
      setInValue('')
      setInPlaceholder('')
      setInLabel('')
    }
  }, [value, placeholder, label])

  useEffect(() => {
    if (condition === undefined || condition === true) {
      let obj = { key: field }
      if (required) obj.required = required
      if (ifObjectExist(validation.minChar)) obj.minChar = validation.minChar
      if (ifObjectExist(validation.maxChar)) obj.maxChar = validation.maxChar
      if (ifObjectExist(validation.alphabetsOnly)) obj.alphabetsOnly = validation.alphabetsOnly
      if (ifObjectExist(validation.alphaNumeric)) obj.alphaNumeric = validation.alphaNumeric
      connector && connector(obj)
      if (Object.keys(obj).length > 1 && !connector) setInError("Please connect 'formPlug' to 'inputSocket'")
    }
    return () => { connector && connector({ key: field, clearValidation: true }) }
  }, [...(required && typeof required === 'object' ? Object.values(required) : [required]), condition])

  useEffect(() => {
    return () => {
      condition && clearOnHide && connector && connector({ key: field, clearValidation: true, clearValue: true })
    }
  }, [condition])

  function ifObjectExist(obj = {}) {
    return Object.values(obj).join('').length > 0 || obj === true
  }

  function onChangeHandler(e) {
    setInValue(e.target.value)
    onChange && onChange(e.target.value, field)
  }

  if (!condition && condition !== undefined) return null

  return (
    <div id='TextArea' className={classMaker(className, css, 'TextArea')} style={{ marginBottom: mb, marginTop: mt, marginRight: mr, marginLeft: ml }}>
      {inLabel && <label htmlFor={uuid} lang={lang}>{(required?.condition !== undefined ? required?.condition : required) && !hideStar && <em>*</em>}<p>{t(inLabel)}</p></label>}
      <textarea
        id={uuid}
        value={inValue}
        placeholder={t(inPlaceholder)}
        onChange={onChangeHandler}
      // disabled={disabled}
      />
      {(inError || (typeof error === 'object' ? error?.[field] : error)) && <small id='error' className='error'>{inError || (typeof error === 'object' ? t(error?.[field] || '') : t(error))}</small>}
    </div>
  )
}
TextArea.displayName = 'TextArea'


{/* 

<TextArea
  className={''}
  label={'Comment'}
  error={'error message'}
  placeholder={'Comment here'}
  value={''}
  onChange={(e) => { }}
/> 

*/}