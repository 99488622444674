import React from 'react'
import './ProgressBar.scss'
import { ProgressBar as PB } from 'react-bootstrap'
import { classMaker } from '../..'

export default function ProgressBar({ className, css, progress, width }) {

  return (
    <div id='ProgressBar' className={classMaker(className, css, 'ProgressBar')} style={{ width: width }}>
      <PB animated now={progress} />
    </div>
  )
}