import React, { useState } from 'react'
import Page from '../../_common/_components/Page/Page'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import List from '../../_common/_components/List/List'
import useFetch from '../../_common/_Hooks/useFetch'
import useTranslate from '../../_common/_Hooks/useTranslate'
import DateViewer from '../../_common/_components/DateViewer/DateViewer'
import TitleBar from '../../_common/_components/TitleBar/TitleBar'
import { useNavigate } from 'react-router-dom'
import Button, { Buttons } from '../../_common/_components/Button/Button'
import { NavBarLabelCounts } from '../../_common/_components/NavBar/NavBar'
import { storeUpdate } from '../../_common'
import { useSelector } from 'react-redux'

export default function Notifications() {

    const { t, _lang } = useTranslate()
    const navigate = useNavigate()
    const notifications = useSelector(s => s.notifications)
    // const [notifications, setNotifications] = useState([])

    // *********************************************************************************************************************
    const { req: getNotifications, res: details } = useFetch({
        endpoint: 'notification/getAllNotification',
        method: 'post',
        type: 'raw',
        selfCall: { limit: 15, page: 0 },
        success: (res) => {
            // res.page.page === 0 && setNotifications(res.data)
            res.page.page === 0 && storeUpdate({ notifications: res.data })
            res.page.totalCount && storeUpdate({ notifications_count: res.page.totalCount })
            NavBarLabelCounts && NavBarLabelCounts({
                Notifications: res.page.totalCount > 99 ? '+99' : res.page.totalCount,
                Alerts: res.alertCount > 99 ? '+99' : res.alertCount
            })
        }
    })
    // *********************************************************************************************************************
    const { req: readNotification } = useFetch({
        endpoint: 'notification/deleteNotification',
        method: 'post',
        type: 'raw',
        privateLoader: true
    })
    // *********************************************************************************************************************
    const { req: deleteAllNotifications } = useFetch({
        endpoint: 'notification/deleteAllNotification',
        method: 'post',
        type: 'raw',
        privateLoader: true
    })
    // *********************************************************************************************************************

    function deleteHandler(item) {
        readNotification({ id: item._id }, () => {
            getNotifications()
        })
    }

    function onAllClearHandler() {
        deleteAllNotifications({}, () => {
            getNotifications()
        })
    }

    function closeButtonHandler(e, item) {
        e.stopPropagation()
        deleteHandler(item)
    }

    function loadMoreHandler() {
        getNotifications({ page: details?.page?.page + 1, limit: 15 }, (res) => {
            // setNotifications(s => {
            //     return [...s, ...res.data]
            // })
            storeUpdate((s) => ({ notifications: [...s.notifications, ...res.data] }))
        })
    }

    return (
        <Page className='Notifi'>
            <Page.Header>
                <Title title="Notifications" />
                <Breadcrumb content={[
                    { path: '/', name: 'Main Menu' },
                    { name: 'Notifications' }
                ]} />
            </Page.Header>

            <Page.Body css={1}>
                <TitleBar css={1} title='Notification List' titleEndElement={(notifications?.length > 0) && <Button css={'1.1.1'} className='noti_clear' content='Clear All' onClick={onAllClearHandler} />}>
                    {notifications?.length > 0
                        ? <List css={4.1} items={notifications}>
                            {
                                (item, i) => {
                                    return <div className='notification_item' onClick={() => {
                                        deleteHandler(item)
                                        item.request_id && navigate('/incident/myrequests/view_request/' + item.request_id)
                                    }}>
                                        <span>
                                            <p className='content'>{item['title' + _lang]}</p>
                                            <p className='at'><DateViewer dateTime={item.createdAt} dateOnly /></p>
                                        </span>
                                        <p className='on'>
                                            <DateViewer dateTime={item.createdAt} timeOnly />
                                            <img className='close_icon' width={20} src="/assets/icons/closeBtn.svg" alt="" onClick={(e) => closeButtonHandler(e, item)} />
                                        </p>
                                    </div>
                                }
                            }
                        </List>
                        : <p style={{ textAlign: 'center' }}>{t('No data available')}</p>}
                    {details?.page?.hasNext &&
                        <Buttons center mb={30} mt={10}>
                            <Button css={1} content='Load More' onClick={loadMoreHandler} />
                        </Buttons>
                    }
                </TitleBar>
            </Page.Body>
        </Page>
    )
}