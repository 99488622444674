import Button from "../_common/_components/Button/Button";
import Card from "../_common/_components/Card/Card";
import Checkbox from "../_common/_components/Checkbox/Checkbox";
import FileUpload from "../_common/_components/FileUpload/FileUpload";
import Input from "../_common/_components/Input/Input";
import RadioButtons from "../_common/_components/RadioButtons/RadioButtons";
import Select from "../_common/_components/Select/Select";
import StatusBox from "../_common/_components/StatusBox/StatusBox";
import Table from "../_common/_components/Table/Table";
import Dash_card from "../components/Dash_card/Dash_card";

const styles =
{
    Dash_card: [
        <Dash_card />
    ],
    Button: [
        <Button css={1} />,
        <Button css={1.1} />,
        <Button css={1.2} content="x" />,
        <Button css={2} />,
        <Button css={3} />,
    ],

    StatusBox: [
        <div style={{ display: "flex", gap: 20, flexWrap: 'wrap' }}>
            <StatusBox css={1} status="todo" />
            <StatusBox css={1} status="assigned" />
            <StatusBox css={1} status="processing" />
            <StatusBox css={1} status="resolved" />
            <StatusBox css={1} status="closed" />
            <StatusBox css={1} status="draft" />
            <StatusBox css={1} status="forwarded" />
            <StatusBox css={1} status="needinfo" />
            <StatusBox css={1} status="escalated" />
            <StatusBox css={1} status="waitingforapproval" />
            <StatusBox css={1} status="approved" />
            <StatusBox css={1} status="changerequested" />
            <StatusBox css={1} status="reopened" />
            <StatusBox css={1} status="notresponded" />
            <StatusBox css={1} status="confirmed" />
            <StatusBox css={1} status="cancelled" />
            <StatusBox css={1} status="rejected" />
            <StatusBox css={1} status="completed" />
            <StatusBox css={1} status="creator" />
        </div>,
        <div style={{ display: "flex", gap: 20, flexWrap: 'wrap' }}>
            <StatusBox css={3} status="call back details updated" />
            <StatusBox css={3} status="call back request" />
            <StatusBox css={3} status="voice mail request" />
            <StatusBox css={3} status="website request" />
            <StatusBox css={3} status="mobile app request" />
            <StatusBox css={3} status="draft" />
            <StatusBox css={3} status="request created" />
            <StatusBox css={3} status="processing" />
            <StatusBox css={3} status="forwarded" />
            <StatusBox css={3} status="escalation approval submission" />
            <StatusBox css={3} status="escalated" />
            <StatusBox css={3} status="closed" />
            <StatusBox css={3} status="resolved" />
            <StatusBox css={3} status="assigned" />
            <StatusBox css={3} status="assigned to pool" />
            <StatusBox css={3} status="additional information required" />
            <StatusBox css={3} status="reopened" />
            <StatusBox css={3} status="rejected escalation request" />
            <StatusBox css={3} status="shared to pool" />
            <StatusBox css={3} status="shared to user" />
        </div>
    ],

    Card: [
        <Card css={1} title='Card Header' subTitle={'Subtitle'}><p>Content</p></Card>
    ],

    Checkbox: [
        <Checkbox />
    ],

    Radio_Button: [
        <RadioButtons
            css={1}
            label='Select Gender'
            buttons={[
                { label: 'Male', value: true },
                { label: 'Female', value: false }
            ]}
            field='gender'
            value={true}
            error=''
        />
    ],

    FileUpload: [
        <FileUpload
            css={1}
            icon={'/assets/icons/upload.svg'}
            buttonLabel="Browse"
            showPreview accept={['pdf', 'jpeg', 'mov']}
            multiple
        />,
        <FileUpload
            css={2}
            dragNdrop
            moreText='Drag and Drop your incident related file here or'
            icon={'/assets/icons/upload.svg'}
            buttonLabel="Browse"
            showPreview accept={['pdf', 'jpeg', 'mov']}
            multiple
        />
    ],

    Input: [
        <Input css={1} showCloseBtn label={'First Name'} icon={'/logo192.png'} />,
        <Input css={2} showCloseBtn label={'First Name'} icon={'/logo192.png'} />,
        <Input css={3} showCloseBtn label={'First Name'} icon={'/logo192.png'} />,
        <Input css={4} showCloseBtn label={'First Name'} icon={'/logo192.png'} />,
    ],

    Select: [
        <Select />
    ],

    Table: [
        <Table
            css={1}
            titles={[
                { title: 'Sl No', key: 'sl_no', width: '', h_align: '', d_align: '', sort: true },
                { title: 'First Name', key: 'first_name', width: '', h_align: '', d_align: '', sort: true },
                { title: 'Last Name', key: 'last_name', width: '', h_align: '', d_align: '', sort: true },
            ]}
            content={[
                { sl_no: 1, first_name: 'user name', last_name: 'last name' },
                { sl_no: 2, first_name: 'first name', last_name: 'user name' }
            ]}
            sortValue={{ key: '', value: '' }}
            onSort={() => { }}
            page={''}
            pagesCount={''}
            onPageChange={() => { }}
            pageStatus={''}
            top
        />
    ],

}
export default styles