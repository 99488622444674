import React from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'
import './Layout.scss'
import { classMaker } from '../..'
import Others from '../Others/Others'

export default function Layout({ className, css, sidebar, header, type }) {

    const outletContext = useOutletContext()

    return type === 1
        ?
        <div id='Layout' className={classMaker(className, css, 'Layout') + (type === 1 ? ' t1' : '')}>
            <div>
                <header>{header}</header>
                <main>
                    <Outlet context={outletContext} />
                    <Others />
                </main>
            </div >
        </div >
        :
        <div id='Layout' className={classMaker(className, css, 'Layout')}>
            <aside>{sidebar}</aside>
            <div>
                <header>{header}</header>
                <main>
                    <Outlet context={outletContext} />
                    <Others />
                </main>
            </div>
        </div>
}
