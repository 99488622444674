import React, { Children, useContext, useEffect, useState } from 'react'
import './ModalContent.scss'
import useTranslate from '../../../_common/_Hooks/useTranslate'
import { classMaker } from '../..'
import useForm from '../../_Hooks/useForm'
import { ModalContext } from '../ModalsContainer/ModalsContainer'
import icon_close from './close1.svg'

export const ModalContentContext = React.createContext()
export default function ModalContent({ className, css, header, children, width, height, autoHide, hideCloseButton, pages, fileUploader }) {

  const { handler, m_inputs, autoHider, closeBtnHideHandler, setShow, showCloseBtn } = useContext(ModalContext)

  const { t } = useTranslate()

  const [Buttons, setButtons] = useState([])
  const [NonButtons, setNonButtons] = useState([])
  const [page, setPage] = useState(0)

  const { formPlug, inputs, validate } = useForm(m_inputs, { fileUploader })

  useEffect(() => {
    autoHide && autoHider(autoHide)
    closeBtnHideHandler(hideCloseButton)
    if (children instanceof Function) {
      children = children({ inputs })
      children = (React.isValidElement(children) && children.type === React.Fragment)
        ? children.props.children
        : children
    }
    if (pages) {
      children = React.Children.toArray(children).filter(child => child)
      children.map((child, i) => {
        const { buttons, nonButtons } = buttonFinder(child.props.children)
        setButtons(s => ({ ...s, [i]: buttons }))
        setNonButtons(s => ({ ...s, [i]: nonButtons }))
      })
    }
    else {
      children = React.Children.toArray(children).filter(child => child)
      children = children.length === 1 ? children[0] : children
      const { buttons, nonButtons } = buttonFinder(
        (React.isValidElement(children) && children.type === React.Fragment)
          ? children.props.children
          : children
      )
      setButtons(buttons)
      setNonButtons(nonButtons)
    }

    return () => {
      setButtons([])
      setNonButtons([])
    }
  }, [inputs])

  function buttonFinder(items) {
    let buttons = []
    let nonButtons = []
    Children.forEach(items, (child) => {
      if (child?.type?.displayName === 'Button') buttons.push(child)
      else nonButtons.push(child)
    })
    return { buttons, nonButtons }
  }

  function closePosHandler(key) {
    if (typeof showCloseBtn !== 'string') return ''
    let opts = showCloseBtn.split(' ')
    if (key === 'left') { return opts[0] === 'left' ? Number(opts[1] || 0) : 'auto' }
    if (key === 'right') { return opts[0] === 'right' ? Number(opts[1] || 0) : 'auto' }
    if (key === 'top') { return Number(opts[2] || Number(opts[1] || 0)) }
  }

  return (
    <div id='ModalContent' className={classMaker(className, css, 'ModalContent') + ' ' + (header?.toLowerCase().split(' ').join('_') || '')} style={{ width: width || 600, height: height }}>
      <ModalContentContext.Provider value={{ formPlug, inputs, validate }}>
        {header && <p className='header'>{t(header)}</p>}
        <div className="content">
          {(pages ? NonButtons[page] : NonButtons)?.map((item, i) => {
            if (['Input', 'Select', 'TextArea', 'FileUpload', 'RadioButtons', 'DatePick', 'Checkbox', 'Choose'].includes(item?.type?.displayName))
              return React.cloneElement(item, {
                key: i,
                inputSocket: formPlug
              })
            else return item
          })}
          <div className='buttons'>
            {(pages ? Buttons[page] : Buttons)?.map((btn, i) => {
              let propClick = btn.props.onClick
              return React.cloneElement(btn, {
                key: i,
                onClick: async () => {
                  if (propClick) { propClick() }
                  if (!btn.props.field && !(btn.props.next || btn.props.back)) { alert('Please\xA0add\xA0field\xA0to\xA0the\xA0Button'); return }
                  if (btn.props.validator && !await validate()) return
                  if (btn.props.next) return setPage(s => s + 1)
                  if (btn.props.back) return setPage(s => s - 1)
                  handler(btn.props.field, inputs)
                }
              })
            })}
          </div>
        </div>
        {showCloseBtn && <img src={icon_close} className={'close_btn'} alt="" onClick={() => setShow(false)}
          style={{ left: closePosHandler('left'), right: closePosHandler('right'), top: closePosHandler('top') }} />}
      </ModalContentContext.Provider>
    </div>
  )
}