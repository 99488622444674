import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import './NavBar.scss'
import { CommonContext, classMaker } from '../..'
import { AppContext } from '../../App'
import useTranslate from '../../_Hooks/useTranslate'
import icon_arrow from './nav_arrow.svg'

export let NavBarLabelCounts;

export default function NavBar({ className, css, menuList }) {

    const { t, lang } = useTranslate()
    const ref = useRef()
    const { setNavbarObj } = useContext(CommonContext)
    const { permissions } = useContext(AppContext)

    const [selectedIndex, selectIndex] = useState()
    const [labelCounts, setLabelCounts] = useState({})

    NavBarLabelCounts = (arg) => {
        setLabelCounts(s => ({ ...s, ...arg }))
    }

    useEffect(() => {
        ref.current?.querySelector('a.active')?.scrollIntoView({ block: "center" })
    }, [])

    function unwrapper(items) {
        return items.flatMap(item => {
            return item.wrapper_component
                ? unwrapper(item.items)
                : item
        })
    }

    function menuMaker({ items, parentPath, parentLabel, pathsArr, isPrimary, level }) {

        return unwrapper(items).map((menu, i) => {

            let path = parentPath ? parentPath + '/' + menu.path : menu.path
            let title = menu.breadcrumb === false ? '' : menu.breadcrumb || menu.title || menu.label || menu.path
            let breadcrumb_str = parentLabel ? parentLabel + '>' + title.replace(/\/:[a-zA-Z]*/, '') : title
            let breadcrumbPaths = (parentPath && pathsArr) ? [...pathsArr, path] : [menu.path]

            let prs = Object.keys(permissions || {})
            let hasPermission = true
            let arr = []

            prs.forEach(k => {
                if (typeof menu[k] === 'string') {
                    arr.push(menu[k] === permissions[k])
                } else if (Array.isArray(menu[k])) {
                    arr.push(menu[k]?.includes(permissions[k]))
                } else if (typeof menu[k] === 'boolean') {
                    arr.push(permissions[k])
                }
            })

            hasPermission = arr.length > 0 ? !arr.some(s => s === false) : true

            if (!hasPermission) return

            if (!menu?.label && !menu.title) return <PathCollector key={i} menu={menu} setItems={setNavbarObj} url={path} breadcrumbs={breadcrumb_str} breadcrumbPaths={breadcrumbPaths} />

            if (menu?.hide === true) return

            let subMenus = (menu.items && !menu?.hideChildren && menu.items.some(i => i.label || i.breadcrumb))
                ? menuMaker({ items: menu.items, parentPath: path, parentLabel: breadcrumb_str, pathsArr: breadcrumbPaths, level: level + 1 })
                : [undefined]

            let hasSubMenus = subMenus.some(e => e !== undefined)

            if (menu.title && !hasSubMenus) return

            return (
                <Fragment key={i}>
                    <PathCollector menu={menu} setItems={setNavbarObj} url={path} breadcrumbs={breadcrumb_str} breadcrumbPaths={breadcrumbPaths} />
                    {
                        menu.title
                            ? <p className='title'>{t(menu.title)}</p>
                            : <NavLink
                                to={(selectedIndex !== i && isPrimary) ? path : (isPrimary ? null : path)}
                                className={({ isActive }) => {
                                    if (!isActive) return
                                    isPrimary && selectedIndex === undefined && selectIndex(i)
                                    return ((selectedIndex === i && isPrimary) ? 'toggle_on' : '') + ' active'
                                }}
                                onClick={() => isPrimary && selectIndex(s => s === i ? null : i)}
                                lang={lang}
                            >
                                {menu.icon && <img className='menu_icon' src={menu.icon} alt='' />}
                                <p>
                                    {t(menu.label)}
                                    {(labelCounts[menu.label] ? <span>(<b> {labelCounts[menu.label]} </b>)</span> : <></>)}
                                </p>
                                {isPrimary && <img className='toggle_icon' src={icon_arrow} alt="" />}
                            </NavLink >
                    }
                    {
                        hasSubMenus && <menu className={`l_${level + 1}`}> {subMenus} </menu>
                    }
                </Fragment>
            )
        })
    }

    return (
        <menu id='NavBar' className={classMaker(className, css, 'NavBar') + ' l_1'} ref={ref}>
            {
                menuMaker({ items: menuList, isPrimary: true, level: 1 })
            }
        </menu>
    )
}

const PathCollector = ({ menu, setItems, url, breadcrumbs, breadcrumbPaths }) => {

    useEffect(() => {
        let path = menu.path?.includes('/') ? menu.path.split('/')[0] : menu.path
        let item = menu.breadcrumb === false ? '' : menu.breadcrumb || menu.title || menu.label || path
        if (url?.includes('/:')) { url = url.replace(/\/:[a-zA-Z]*/, '') }
        setItems(s => ({
            ...s,
            [url]: {
                bds: breadcrumbs.split('>'),
                title: item,
                paths: breadcrumbPaths
            }
        }))
    }, [])

}