import React, { useEffect } from 'react'
import Title from '../../_common/_components/Title/Title'
import Breadcrumb from '../../_common/_components/Breadcrumb/Breadcrumb'
import Div from '../../_common/_components/Div/Div'
import Page from '../../_common/_components/Page/Page'
import Input from '../../_common/_components/Input/Input'
import Grid from '../../_common/_components/Grid/Grid'
import DatePick from '../../_common/_components/DatePick/DatePick'
import Select from '../../_common/_components/Select/Select'
import Button from '../../_common/_components/Button/Button'
import { useNavigate } from 'react-router'
import TitleBar from '../../_common/_components/TitleBar/TitleBar'
import RadioButtons from '../../_common/_components/RadioButtons/RadioButtons'
import TextArea from '../../_common/_components/TextArea/TextArea'
import FooterButtons from '../../_common/_components/FooterButtons/FooterButtons'
import 'react-h5-audio-player/lib/styles.css';
import FileUpload from '../../_common/_components/FileUpload/FileUpload'
import useForm from '../../_common/_Hooks/useForm'
import useFetch from '../../_common/_Hooks/useFetch'
import useTranslate from '../../_common/_Hooks/useTranslate'
import Flex from '../../_common/_components/Flex/Flex'
import { ActionsModalHandler } from '../../common/Modals'
import { useSelector } from 'react-redux'
import Phone from '../../_common/_components/Phone/Phone'
import { socket } from '../../components/InitialApis'

export default function AddRequest() {

    const navigate = useNavigate()
    const { t, _lang } = useTranslate()

    const department = useSelector(s => s.department)
    const user = useSelector(s => s.user)

    let isAgent = department.handle_key === "customer_service"
    let isCreationByOtherDepartment = department.handle_key !== 'customer_service'

    const genders = [
        { label: "Male", value: "male" },
        { label: "Female", value: "female" }
    ]

    // *********************************************************************************************************************
    const { req: addRequest } = useFetch({
        endpoint: 'request/addrequest',
        method: 'post',
        type: 'raw',
        toast: true,
    })
    // *********************************************************************************************************************
    const { res: countries } = useFetch({
        endpoint: 'common/getFullCountries',
        method: 'get',
        type: 'raw',
        toast: false,
        selfCall: true
    })
    // *********************************************************************************************************************
    const { res: incidentTypes_res } = useFetch({
        endpoint: 'incidentType/getIncidentType',
        method: 'post',
        type: 'raw',
        toast: false,
        selfCall: true
    })
    // *********************************************************************************************************************
    const { res: forwardDepartments_res } = useFetch({
        endpoint: 'department/getForwardingDepartments',
        method: 'get',
        type: 'raw',
        selfCall: true
    })
    // *********************************************************************************************************************
    const { res: actions_res } = useFetch({
        endpoint: 'common/getAllStatus',
        method: 'get',
        type: 'raw',
        toast: false,
        selfCall: true
    })
    // *********************************************************************************************************************
    const { req: findCustomer } = useFetch({
        endpoint: 'request/findCustomerByContactNumber',
        method: 'post',
        type: 'raw',
        privateLoader: true
    })
    // *********************************************************************************************************************
    const { req: uploadFiles, lod: fileUploadLoader, pro: fileUploadProgress } = useFetch({
        endpoint: 'file/uploadFile',
        method: 'post',
        type: 'formdata',
        toast: false,
        privateLoader: true
    })
    // *********************************************************************************************************************
    const { req: getIncidentDetails, res: incidentTypeDetails } = useFetch({
        endpoint: "incidentType/getIncidentTypeDetail",
        type: 'params',
        method: "get",
    })
    const subincidentTypes = incidentTypeDetails?.data?.subIncidentTypeData
    // *********************************************************************************************************************
    let incidentTypes = [...(incidentTypes_res?.data || [])]
    incidentTypes?.sort((a, b) => {
        if (b.handle_key === 'other') return -1
        else return 0
    })
    let forwardDepartments = forwardDepartments_res?.data
    let actions = actions_res?.data
    let actionsForCurrentUser = actions?.filter(a => { if (isAgent) return ['processing', 'create_request', 'draft'].includes(a.handle_key) })
    // *********************************************************************************************************************

    const { formPlug, inputs, validate, setInputs, inputHandler } = useForm({
        country_code: {
            "_id": "667e5754a017a0408a9f1f5b",
            "country": "United Arab Emirates",
            "handle_key": "united_arab_emirates",
            "code": "+971",
            "flag": "united_arab_emirates.svg"
        },
    }, { fileUploader: uploadFiles });

    async function submitHandler(action) {
        let act = action || inputs.action
        if (!await validate()) return
        const { cancel, m_inputs } = await ActionsModalHandler(inputs, act, _lang, user, { forwardDepartments, page: 'addRequest' })
        if (cancel) return
        // const ipRes = await axios.get('https://api.ipify.org/?format=json')
        addRequest(() => ({
            track_id: inputs.track_id,
            customer_name_en: inputs.customer_name_en,
            customer_contact: inputs.customer_contact,
            customer_email: inputs.customer_email,
            customer_nationality: inputs.customer_nationality?._id,
            customer_gender: inputs.customer_gender,
            incident_type: inputs.incident_type._id,
            sub_incident_type: inputs.sub_incident_type._id,
            incident_date: inputs.incident_date,
            incident_file: inputs.incident_file_filenames,
            descripition: inputs.descripition,
            comment: m_inputs.comment,
            attachments: inputs.attachments_filenames,
            submit_type: act._id,
            to_departmentId: m_inputs.frwrd_to_department?._id,
            customer_contact_code: inputs.country_code?.code
            // ip_addr: ipRes.data.ip
            // approvalRequired: m_inputs.approvalRequired,
        }), (res) => {
            if (res.status) {
                if (res.isSocket_triger) { socket.emit('actionMessages', res.socketObj) }

                (act.handle_key === 'create_request')
                    ? isAgent
                        ? navigate('/incident/requests')
                        : navigate('/incident/myrequests')
                    : navigate('/incident/myrequests/view_request/' + res.data._id, { state: { back: 'myrequests' } })
            }
        })
    }

    function customerFindHandler(value) {
        findCustomer({
            customer_contact: value,
            checking_for: 'phone'
        }, (res) => {
            setInputs(s => ({
                ...s,
                customer_name_en: s.customer_name_en || res.data?.customer_name_en,
                customer_email: s.customer_email || res.data?.customer_email,
                customer_nationality: s.customer_nationality || res.data?.customer_nationality,
                customer_gender: s.customer_gender || res.data?.customer_gender,
            }))
        })
    }

    return (
        <Page>

            <Page.Header>
                <Title title='Add Request' />
                <Breadcrumb />
            </Page.Header>

            <Page.Body css={1} >
                <Div css={1} className='p-0'>
                    <TitleBar css={1} title='Customer Details' toggleButton>
                        <div className="pad-20">
                            <Flex columns={5} gap={15} _minWidth={200}>
                                <Phone
                                    css={1}
                                    label='Phone Number'
                                    field='customer_contact'
                                    type='number'
                                    validation={{ phone: { digits: "6-13" }, maxNumber: 13 }}
                                    inputSocket={formPlug}
                                    countryCode_inputSocket={formPlug}
                                    onBlur={customerFindHandler}
                                />
                                <Input
                                    css={1}
                                    label='Email'
                                    field='customer_email'
                                    inputSocket={formPlug}
                                    validation={{ email: true }}
                                />
                                <Input
                                    css={1}
                                    label='Name [En]'
                                    field='customer_name_en'
                                    inputSocket={formPlug}
                                />
                                <Select
                                    css={1}
                                    label="Nationality"
                                    field='customer_nationality'
                                    content={{ op: countries?.data, vl: '_id', lb: 'name' }}
                                    inputSocket={formPlug}
                                />
                                <RadioButtons
                                    css={1}
                                    label="Gender"
                                    field="customer_gender"
                                    buttons={genders}
                                    inputSocket={formPlug}
                                />
                            </Flex>
                        </div>
                    </TitleBar>
                </Div>
                <Div css={1} className='mt-4 p-0'>
                    <TitleBar css={1} title='Incident Details' toggleButton>
                        <div className="pad-20">
                            <Grid columns={4}>
                                <Select
                                    css={1}
                                    label="Incident Type"
                                    field='incident_type'
                                    content={{ op: incidentTypes, vl: '_id', lb: 'incident_type' + _lang }}
                                    inputSocket={formPlug}
                                    onChange={(e, key) => {
                                        getIncidentDetails(e._id)
                                        inputHandler(e, key)
                                        inputHandler('', 'sub_incident_type')
                                    }}
                                    required
                                />
                                <Select
                                    css={1}
                                    label="Sub Incident Type"
                                    field='sub_incident_type'
                                    content={{ op: subincidentTypes, vl: '_id', lb: 'sub_incident_type' + _lang }}
                                    inputSocket={formPlug}
                                />
                                <DatePick
                                    css={1}
                                    placeholder='Date'
                                    label='Incident Date'
                                    field='incident_date'
                                    inputSocket={formPlug}
                                    // maxDate={new Date()}
                                    showYearDropdown
                                    showMonthDropdown
                                    timeSelect
                                />
                            </Grid>
                            <Grid columns={1} className='mt-3 gap-0'>
                                <FileUpload
                                    css={2}
                                    label={'Add Attachments'}
                                    dragNdrop
                                    moreText='Drag and Drop your incident related file or Click here'
                                    // buttonLabel="Browse"
                                    // maxFileSizeMB={50}
                                    accept={{ items: ["jpg", "jpeg", "png", "pdf", "wav", "mp3"], message: 'Only jpg, jpeg, png, pdf, wav and mp3 formats accepted' }}
                                    field="incident_file"
                                    showPreview
                                    icon={'/assets/icons/upload.svg'}
                                    multiple
                                    inputSocket={formPlug}
                                    progress={fileUploadProgress}
                                />
                                <p className='add_attachments'>jpg, jpeg, png, pdf, wav, mp3 : {t('Supporting formats')}</p>
                            </Grid>
                            <Grid columns={1} className='mt-3'>
                                <TextArea
                                    css={2}
                                    placeholder='Text Here'
                                    label='Description'
                                    field='descripition'
                                    inputSocket={formPlug}
                                />
                            </Grid>
                        </div>
                    </TitleBar>
                </Div>

                <Div css={1} className='mt-4 p-0'>
                    <TitleBar css={1} title='Comment' toggleButton>
                        <div className="pad-20 pt-0">
                            <TextArea
                                css={1}
                                placeholder='Text Here'
                                field='comment'
                                inputSocket={formPlug}
                                validation={{ maxChar: { length: 2000 } }}
                            />
                        </div>
                    </TitleBar>
                </Div>
                <FileUpload
                    css={1}
                    label=""
                    buttonLabel="Attach File"
                    maxFileSizeMB={50}
                    className='mt-4'
                    accept={{ items: ["jpg", "jpeg", "png", 'pdf', 'mp4'], message: 'Only jpg, jpeg, png, pdf and mp4 formats accepted' }}
                    field="attachments"
                    showPreview
                    multiple
                    icon={'/assets/icons/add.svg'}
                    inputSocket={formPlug}
                    loader={fileUploadLoader}
                />
                <FooterButtons>
                    <Button right content="Back" css={1} icon="/assets/icons/back.svg" reverse iconWidth="15px" onClick={() => navigate(-1)} />
                    <Select
                        css={2}
                        left
                        placeholder="Select"
                        field='action'
                        inputSocket={formPlug}
                        onChange={(value, key) => {
                            // inputHandler(value, key)
                            submitHandler(value)
                        }}
                        content={{
                            op: actionsForCurrentUser,
                            lb: 'action_status',
                            vl: '_id',
                        }}
                        // required
                        width={150}
                        height={45}
                        condition={!isCreationByOtherDepartment}
                    />
                    {isCreationByOtherDepartment && <Button left content="Create" css={1} onClick={() => submitHandler(actions.find(a => a.handle_key === 'create_request'))} />}
                    <Button left content="Cancel" css={1} onClick={() => navigate(-1)} />
                </FooterButtons>
            </Page.Body>

        </Page>
    )
}